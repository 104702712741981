import React from 'react';
import Layout from '../../components/Layout';
import {connect} from 'react-redux';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';


const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 10,
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});


class CatalogUploadPage extends React.Component {
  render() {
    return (
      <Layout
        content={this.renderContent()}
      />
    );
  }

  renderContent() {
    return (
      <div className={this.props.classes.container}>
        {this.renderUploader()}
      </div>
    )
  }

  renderUploader() {
    const {classes} = this.props;
    
    return (
      <div>
        <input className={classes.input} id="file" multiple type="file" />
        <label htmlFor="file">
          <Button raised component="span" className={classes.button}>
            Загрузить файл с товарами
          </Button>
        </label>
      </div>
    )
  }
}



export default connect(
  state => ({
  }),
  (dispatch, ownProps) => ({
  })
)(withStyles(styles)(CatalogUploadPage));
