import React from 'react';
import {TableRow, TableCell} from 'material-ui/Table';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';


const firstRowColumnStyle = {
  width: 30
};

const openButtonStyle = {
  width: 40,
  height: 40
};

const productPhotoStyle = {
  maxWidth: 100
};

class CatalogListRow extends React.PureComponent {
  handleOpen = () => {
    this.props.onOpen(this.props.product);
  };

  render() {
    const product = this.props.product;

    return (
      <TableRow>
        <TableCell style={firstRowColumnStyle}>
          <Button color="accent" fab onClick={this.handleOpen} style={openButtonStyle}>
            <Icon className="material-icons">details</Icon>
          </Button>
        </TableCell>
        <TableCell>
          {product.ID}
        </TableCell>
        <TableCell>
          {product.PREVIEW_PICTURE_URL ?
            <img src={product.PREVIEW_PICTURE_URL} style={productPhotoStyle} alt="Фото"/>
            :
            <a>Фото отсутствует</a>
          }
        </TableCell>
        <TableCell>
          <a>{product.PROPERTIES.ARTICUL.value}</a><br/>
          <a>{product.PROPERTIES.BRAND.value}</a><br/>
          <a>{product.PROPERTIES.COLLECTION.value}</a><br/>
          <a>{product.PROPERTIES.COLOR.value}</a>
        </TableCell>
        <TableCell>
          {product.SECTION_NAMES.reverse().join(' -> ')}
        </TableCell>
      </TableRow>
    );
  }
}

export default CatalogListRow;
