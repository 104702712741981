import React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import { Row, Cell, Block } from '../../../../components/SubLayout';
import TextField from 'material-ui/TextField';
import CustomSelect from '../../../../components/CustomSelect';
const { getMetroStations, getCities, getCountries } = require('../../../../selectors').default;


const textFieldStyle = {
  fontSize: 13,
  width: '100%'
};

const memoizedOptions = R.memoize(
  items => items.map(
    ({ id, name }) =>
      ({ id, text: name })
  )
);

const memoizedStations = R.memoize(
  items => items.map(
    ({ id, name }) => ({ id: name, text: name })
  )
);

class DeliveryAddress extends React.PureComponent {
  render() {
    const order = this.props.order;
    const showmodules = order.showmodules ? order.showmodules : {};
    const deliveryparams = showmodules.deliveryparams;
    return (
      deliveryparams ? this.render2() : this.render1()
    )
  }
  render2() {
    return (
      <Block title=""></Block>
    )
  }
  render1() {
    return (
      <Block title="Адрес доставки">
        <Row>
          <Cell>
            <CustomSelect
              label="Страна"
              options={memoizedOptions(this.props.countries)}
              value={this.props.COUNTRY_ID}
              onChange={this.props.setFieldValue(['COUNTRY_ID'])}
            />
          </Cell>
          <Cell>
            <CustomSelect
              label="Город"
              options={memoizedOptions(this.props.cities)}
              value={this.props.CITY_ID}
              onChange={this.props.setFieldValue(['CITY_ID'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField
              label="Почтовый индекс"
              value={this.props.ZIP || ''}
              onChange={this.props.setFieldValue(['props', 'ZIP'])}
              style={textFieldStyle}
            />
          </Cell>
          <Cell>
            <CustomSelect
              label="Станция метро"
              options={memoizedStations(this.props.metroStations)}
              value={this.props.METRO_STATION || ''}
              onChange={this.props.setFieldValue(['props', 'METRO_STATION'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField
              label="Улица"
              value={this.props.STREET || ''}
              multiline
              onChange={this.props.setFieldValue(['props', 'STREET'])}
              style={textFieldStyle}
            />
          </Cell>
          <Cell>
            <TextField
              label="Дом"
              value={this.props.MAILBOX || ''}
              onChange={this.props.setFieldValue(['props', 'MAILBOX'])}
              style={textFieldStyle}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField
              label="Корпус"
              value={this.props.BUILDING || ''}
              onChange={this.props.setFieldValue(['props', 'BUILDING'])}
              style={textFieldStyle}
            />
          </Cell>
          <Cell>
            <TextField
              label="Подъезд"
              value={this.props.ENTRANCE || ''}
              onChange={this.props.setFieldValue(['props', 'ENTRANCE'])}
              style={textFieldStyle}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField
              label="Этаж"
              value={this.props.FLOOR || ''}
              onChange={this.props.setFieldValue(['props', 'FLOOR'])}
              style={textFieldStyle}
            />
          </Cell>
          <Cell>
            <TextField
              label="Квартира"
              value={this.props.NOTES || ''}
              onChange={this.props.setFieldValue(['props', 'NOTES'])}
              style={textFieldStyle}
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default connect(
  state => ({
    metroStations: getMetroStations(state),
    cities: getCities(state),
    countries: getCountries(state)
  })
)(DeliveryAddress);
