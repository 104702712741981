import React from 'react';
import {TableCell} from 'material-ui/Table';

class OrdersTableCell extends React.PureComponent {
  render() {
    const newProps = {
      ...this.props,
      style: {...this.props.style, whiteSpace: 'normal', padding: '0 8px'}
    };
    return <TableCell {...newProps}>{this.props.children}</TableCell>;
  }
}

export default OrdersTableCell;
