const types = require('./types');


export const setBasketStatusId = (index, statusId) => ({
    type: types.SET_BASKET_STATUS_ID,
    index,
    statusId
});

export const setBasketSold = (index, is_sold) => ({
    type: types.SET_BASKET_SOLD,
    index,
    is_sold
});

export const setBasketQty = (index, QUANTITY) => ({
    type: types.SET_BASKET_QTY,
    index,
    QUANTITY
});

export const setBasketPromokod = (index, PROMOKOD) => ({
    type: types.SET_BASKET_PROMOKOD,
    index,
    PROMOKOD
});

export const setBasketAdminComment = (index, ADMIN_COMMENT) => ({
    type: types.SET_ADMIN_COMMENT,
    index,
    ADMIN_COMMENT
});

export const setBasketGoodsManagerComment = (index, value) => ({
    type: types.SET_BASKET_GOODS_MANAGER_COMMENT,
    index,
    value
})

export const removeBasket = index => ({
    type: types.REMOVE_BASKET,
    index
});

export const loadOneOrder = (id, typeId) => ({
    type: types.LOAD_ONE_ORDER,
    id,
    typeId
});

export const loadOrderByCart = cartId => ({
    type: types.LOAD_ORDER_BY_CART,
    cartId
})

export const loadOrders = () => ({
    type: types.LOAD_ORDERS
});

export const checkNewOrder = () => ({
    type: types.CHECK_NEW_ORDER
});

export const loadLastOrderId = () => ({
    type: types.LOAD_LAST_ORDER_ID
});

export const setSourceStock = id => ({
    type: types.SET_SOURCE_STOCK,
    id
});

export const toggleSource = id => ({
    type: types.TOGGLE_SOURCE,
    id
});

export const toggleType = id => ({
    type: types.TOGGLE_TYPE,
    id
});

export const toggleAggregated = value => ({
    type: types.TOGGLE_AGGREGATED,
    value
});

export const toggleStatus = id => ({
    type: types.TOGGLE_STATUS,
    id
});

export const setPage = page => ({
    type: types.SET_PAGE,
    page
});

export const setSearch = search => ({
    type: types.SET_SEARCH,
    search
});

export const setSearchArticle = article => ({
    type: types.SET_SEARCH_ARTICLE,
    article
});

export const setSearchSize = size => ({
    type: types.SET_SEARCH_SIZE,
    size
});

export const createOrderById = (history, id, typeId) => ({
    type: types.CREATE_ORDER_BY_ID,
    history,
    id,
    typeId
});

export const setDateDeliveryFrom = date => ({
    type: types.SET_DATE_DELIVERY_FROM,
    date
});

export const setDateDeliveryTo = date => ({
    type: types.SET_DATE_DELIVERY_TO,
    date
});

export const setDateCreateFrom = date => ({
    type: types.SET_DATE_CREATE_FROM,
    date
});

export const setDateCreateTo = date => ({
    type: types.SET_DATE_CREATE_TO,
    date
});

export const sendConfirmationEmail = id => ({
    type: types.SEND_CONFIRMATION_EMAIL,
    id
});

export const sendConfirmationSms = id => ({
    type: types.SEND_CONFIRMATION_SMS,
    id
});

export const sendEcardSms = id => ({
    type: types.SEND_ECARD_SMS,
    id
});

export const sendDelivSms = id => ({
    type: types.SEND_DELIV_SMS,
    id
});

export const setDraftOrderField = (field, value) => ({
    type: types.SET_DRAFT_ORDER_FIELD,
    field,
    value
});

export const setDraftProductsSearch = search => ({
    type: types.SET_DRAFT_PRODUCTS_SEARCH,
    search
});

export const searchProducts = () => ({
    type: types.SEARCH_PRODUCTS
});

export const saveOrder = (history, id) => ({
    type: types.SAVE_ORDER,
    history,
    id
});

export const openProductsZone = () => ({
    type: types.OPEN_PRODUCTS_ZONE
});

export const addToDraftBasket = (id, size) => ({
    type: types.ADD_TO_DRAFT_BASKET,
    id,
    size
});

export const sendPaymentUrl = id => ({
    type: types.SEND_PAYMENT_URL,
    id
});

export const viewPaymentUrl = id => ({
    type: types.VIEW_PAYMENT_URL,
    id
});

export const sendPaymentSms = id => ({
    type: types.SEND_PAYMENT_SMS,
    id
});

export const calcPrices = id => ({
    type: types.CALC_PRICES,
    id
});

export const ordersExport = () => ({
    type: types.ORDERS_EXPORT
});

export const emptyExportLink = () => ({
    type: types.ORDERS_EXPORT_UNSET_LINK
});