import axios from "axios";
import qs from "qs";
import { delay } from "redux-saga";
import { put, all, takeLatest, select, call } from "redux-saga/effects";

import { loadProductCategories } from "./actions";

const types = require("./types");

const { getCatalogProductsSearch, getProduct } =
  require("../../selectors").default;

function* loadProductsSaga() {
  yield call(delay, 500);

  const state = yield select();
  const search = getCatalogProductsSearch(state);

  const result = yield axios({
    method: "GET",
    url: "/api/products",
    params: {
      tags: [search],
      sort: "ID",
      order: "asc",
      pageSize: 60,
    },
  });

  yield put({
    type: types.LOAD_PRODUCTS_COMPLETED,
    products: result.data.products,
  });
}

function* loadOneProductRemains({ id }) {
  const result = yield axios({
    method: "GET",
    url: `/api/catalog/product/remains/?productId=${id}`,
  });

  yield put({
    type: types.LOAD_ONE_PRODUCT_REMAINS_COMPLETED,
    productRemains: result.data,
  });
}

function* loadOneProductSaga({ id }) {
  const result = yield axios({
    method: "GET",
    url: `/api/products/${id}`,
  });

  yield put({
    type: types.LOAD_ONE_PRODUCT_COMPLETED,
    product: result.data,
  });

  yield put(loadProductCategories(parseInt(result.data.ROOT_SECTION_ID, 10)));
}

function* loadProductStylesSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/catalog/styles",
  });

  yield put({
    type: types.LOAD_PRODUCT_STYLES_COMPLETED,
    styles: result.data,
  });
}

function* loadProductCollectionsSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/catalog/collections",
  });

  yield put({
    type: types.LOAD_PRODUCT_COLLECTIONS_COMPLETED,
    collections: result.data,
  });
}

function* loadProductRootSectionsSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/catalog/rootSections",
  });

  yield put({
    type: types.LOAD_PRODUCT_ROOTSECTIONS_COMPLETED,
    rootSections: result.data,
  });
}

function* loadProductCategoriesSaga({ id }) {
  const result = yield axios({
    method: "GET",
    url: `/api/catalog/sections?rootSectionId=${id}`,
  });

  yield put({
    type: types.LOAD_PRODUCT_CATEGORIES_COMPLETED,
    categories: result.data,
  });
}

function* loadProductBrandsSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/brands",
  });

  yield put({
    type: types.LOAD_PRODUCT_BRANDS_COMPLETED,
    brands: result.data,
  });
}

function* loadProductCarsSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/catalog/cars",
  });

  yield put({
    type: types.LOAD_PRODUCT_CARS_COMPLETED,
    cars: result.data,
  });
}

function* loadProductCountriesSaga() {
  const result = yield axios({
    method: "GET",
    url: "/api/countries",
  });

  yield put({
    type: types.LOAD_PRODUCT_COUNTRIES_COMPLETED,
    countries: result.data,
  });
}

function* setProductRemainsEnabled({ productId, stockId, sizeName }) {
  yield axios({
    method: "POST",
    url: "/api/catalog/product/setRemainsEnabled",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      productId,
      stockId,
      sizeName,
    }),
  });
}

function* setProductRemainsDisabled({ productId, stockId, sizeName }) {
  yield axios({
    method: "POST",
    url: "/api/catalog/product/setRemainsDisabled",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      productId,
      stockId,
      sizeName,
    }),
  });
}

function* setProductOtzyv({ productId, text }) {
  yield axios({
    method: "POST",
    url: "/api/marketer/otzyvy/createOvzyvByProductId",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      product_id: productId,
      text,
    }),
  });
}

function* uploadProductImageSaga({ data }) {
  const result = yield axios({
    method: "POST",
    url: `/api/catalog/product/uploadMainPhoto?id=${data.get("id")}`,
    data,
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  const image = result.data;

  yield put({
    type: types.UPLOAD_PRODUCT_IMAGE_COMPLETED,
    image,
  });
}

function* saveProductSaga({ history }) {
  const state = yield select();
  const product = getProduct(state);

  const productForSave = {};
  const productPropsFields = [
    "PROSPECTIVE_CAR_NUMBER",
    "CACHE_ENABLED",
    "PROSPECTIVE",
    "PROVIDER_ARTICLE",
    "ARTICUL",
    "COLOR",
    "COLOR_LATIN",
    "TOP_MATERIAL",
    "LINING_MATERIAL",
    "SOLE",
    "HEEL",
    "BEST_SELLER",
    "RECOMMENDED",
    "COLLECTION_TYPE",
  ];

  productForSave.props = {};
  productPropsFields.forEach((field) => {
    productForSave.props[field] = product.PROPERTIES[field]
      ? product.PROPERTIES[field].print_value
      : null;
  });
  productForSave.id = product.ID;
  productForSave.desc = `<p>${product.DETAIL_TEXT_STRIPPED}</p>`;
  productForSave.section_id = product.SECTION_ID;
  productForSave.brand_name = product.PROPERTIES.BRAND.print_value;
  productForSave.collection_name = product.PROPERTIES.COLLECTION.print_value;
  productForSave.country_name = product.PROPERTIES.COUNTRY.print_value;
  productForSave.brand_country_name =
    product.PROPERTIES.BRAND_COUNTRY.print_value;
  productForSave.style_name = product.PROPERTIES.STYLE.print_value;

  const result = yield axios({
    method: "POST",
    url: "/api/catalog/product/save",
    data: qs.stringify(productForSave),
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  yield put({
    type: types.LOAD_ONE_PRODUCT_COMPLETED,
    product,
  });
}

function* processFieldChangeSaga({ field, value }) {
  if (field[0] !== "ROOT_SECTION_ID") {
    return;
  }

  const state = yield select();
  const product = getProduct(state);

  yield put(loadProductCategories(product.ROOT_SECTION_ID));
}

export default function* () {
  yield all([
    takeLatest(types.SET_SEARCH, loadProductsSaga),
    takeLatest(types.LOAD_PRODUCTS, loadProductsSaga),
    takeLatest(types.LOAD_ONE_PRODUCT, loadOneProductSaga),
    takeLatest(types.LOAD_ONE_PRODUCT_REMAINS, loadOneProductRemains),
    takeLatest(types.SET_REMAINS_ENABLED, setProductRemainsEnabled),
    takeLatest(types.SET_REMAINS_DISABLED, setProductRemainsDisabled),
    takeLatest(types.SET_OTZYV, setProductOtzyv),
    takeLatest(types.UPLOAD_PRODUCT_IMAGE, uploadProductImageSaga),
    takeLatest(types.SAVE_PRODUCT, saveProductSaga),
    takeLatest(types.LOAD_PRODUCT_STYLES, loadProductStylesSaga),
    takeLatest(types.LOAD_PRODUCT_COLLECTIONS, loadProductCollectionsSaga),
    takeLatest(types.LOAD_PRODUCT_ROOTSECTIONS, loadProductRootSectionsSaga),
    takeLatest(types.LOAD_PRODUCT_CATEGORIES, loadProductCategoriesSaga),
    takeLatest(types.SET_DRAFT_PRODUCT_FIELD, processFieldChangeSaga),
    takeLatest(types.LOAD_PRODUCT_BRANDS, loadProductBrandsSaga),
    takeLatest(types.LOAD_PRODUCT_CARS, loadProductCarsSaga),
    takeLatest(types.LOAD_PRODUCT_COUNTRIES, loadProductCountriesSaga),
  ]);
}
