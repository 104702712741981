import {put, all, takeLatest, select} from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import R from 'ramda';
const types = require('./types');
const {
  getBannersPage,
  getBannersPageSize,
  getBanner,
  getBannersActivityTypesSelected,
  getBannersTypesSelected
} = require('../../../selectors').default;


function* loadBannersDataSaga() {
  const state = yield select();

  const page = getBannersPage(state);
  const pageSize = getBannersPageSize(state);
  const offset = pageSize * (page - 1);

  const activityTypes = getBannersActivityTypesSelected(state);

  const result = yield axios({
    method: 'GET',
    url: '/api/marketer/banner/list',
    params: R.pickBy((val, key) => !!val, {
      offset,
      is_active: activityTypes.length === 1 ? activityTypes[0] : [],
      type_id: getBannersTypesSelected(state)
    }),
    paramsSerializer(params) {
      return qs.stringify(params, {arrayFormat: 'brackets'});
    },
    withCredentials: true
  });

  yield put({
    type: types.LOAD_BANNERS_DATA_COMPLETED,
    banners: result.data.banners,
    pageSize: result.data.pageSize,
    total: result.data.total
  });
}

function* removeBannerSaga({id}) {
  yield axios({
    method: 'POST',
    url: '/api/marketer/banner/delete',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({type: types.LOAD_BANNERS_DATA});
}

function* loadOneBannerSaga({id}) {
  let banner;
  if (id === 0) {
    banner = {};
  } else {
    const result = yield axios({
      method: 'GET',
      url: '/api/marketer/banner/view',
      params: {
        id
      },
      withCredentials: true
    });
    banner = result.data;
  }

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    banner
  });
}

function* loadBannerTypesSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/marketer/banner/types',
    withCredentials: true
  });

  yield put({
    type: types.LOAD_BANNER_TYPES_COMPLETED,
    bannerTypes: result.data
  });
}

function* uploadBannerImageSaga({data}) {
  const result = yield axios({
    method: 'POST',
    url: '/api/marketer/banner/upload',
    data
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    banner: result.data
  });
}

function* removeBannerImageSaga({id, type}) {
  const result = yield axios({
    method: 'POST',
    url: `/api/marketer/banner/${type === 'horizontal' ? 'removeHorizontalImage' : 'removeVerticalImage'}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    banner: result.data
  });
}

function* removeBannerHorizontalImageSaga({id}) {
  yield removeBannerImageSaga({id, type: 'horizontal'})
}

function* removeBannerVerticalImageSaga({id}) {
  yield removeBannerImageSaga({id, type: 'vertical'})
}

function* saveBannerSaga({history}) {
  const state = yield select();
  const banner = getBanner(state);

  const result = yield axios({
    method: 'POST',
    url: '/api/marketer/banner/save',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      ...banner
    })
  });

  if (banner.id) {
    yield put({
      type: types.LOAD_ONE_BANNER_COMPLETED,
      banner: result.data
    });
  } else {
    yield put({
      type: types.LOAD_ONE_BANNER,
      id: result.data.id
    });
    history.push(`/marketer/banners/${result.data.id}`);
  }
}

export default function*() {
  yield all([
    takeLatest(types.LOAD_BANNERS_DATA, loadBannersDataSaga),
    takeLatest(types.REMOVE_BANNER, removeBannerSaga),
    takeLatest(types.LOAD_ONE_BANNER, loadOneBannerSaga),
    takeLatest(types.LOAD_BANNER_TYPES, loadBannerTypesSaga),
    takeLatest(types.UPLOAD_BANNER_IMAGE, uploadBannerImageSaga),
    takeLatest(types.REMOVE_BANNER_HORIZONTAL_IMAGE, removeBannerHorizontalImageSaga),
    takeLatest(types.REMOVE_BANNER_VERTICAL_IMAGE, removeBannerVerticalImageSaga),
    takeLatest(types.SAVE_BANNER, saveBannerSaga)
  ]);
}
