import {put, all, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import R from 'ramda';


const LOAD_BASKETS = 'baskets/LOAD_BASKETS';
const LOAD_BASKETS_COMPLETED = 'baskets/LOAD_BASKETS_COMPLETED';
const CREATE_ORDER_BY_CART = 'baskets/CREATE_ORDER_BY_CART';
const SET_SEARCH = 'baskets/SET_SEARCH';


const initialState = {
  baskets: [],
  search: '',
  loading: false,
  loaded: false
}

export function reducer (state = initialState, action) {
  switch(action.type) {
    case LOAD_BASKETS:
      return {
        ...state,
        loading: true
      };
    case LOAD_BASKETS_COMPLETED:
      return {
        ...state,
        baskets: action.baskets,
        loading: false,
        loaded: true
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.search
      }
    default:
      return state;
  }
}

export const loadBaskets = (search) => ({
  type: LOAD_BASKETS,
  search
});

export const createOrderByCart = (history, id) => ({
  type: CREATE_ORDER_BY_CART,
  history,
  id
});

export const setSearch = search => ({
  type: SET_SEARCH,
  search
});

function* loadBasketsSaga({search}) {
  const result = yield axios({
    method: 'GET',
    url: '/api/operator/sessions/list',
    params: {
      sessionIdInt: search
    }
  });

  yield put({
    type: LOAD_BASKETS_COMPLETED,
    baskets: result.data.items
  });
}

function* createOrderByCartSaga({history, id}) {
  yield history.push(`/operator/orders/new-by-cart/${id}`);
}


export function* saga() {
  yield all([
    takeLatest(LOAD_BASKETS, loadBasketsSaga),
    takeLatest(CREATE_ORDER_BY_CART, createOrderByCartSaga),
    takeLatest(SET_SEARCH, loadBasketsSaga)
  ]);
}

export const selectors = {
  getBasketsList: R.path(['baskets']),
  getBasketsLoading: state => state.loading,
  getBasketsLoaded: state => state.loaded,
  getBasketsSearch: state => state.search
}
