import R from 'ramda';
const types = require('./types');


const initialState = {
  bannerswc: [],
  bannerwc: {},
  pageSize: 40,
  total: 0,
  page: 1,
  bannerwcTypes: [],
  loading: false,
  loaded: false,
  filters: {
    activity: [],
    types: []
  }
};

const toggleArrayItem = (path, value, state) => {
  const lens = R.lensPath(path);

  let values = R.view(lens, state) || [];
  const index = R.indexOf(value, values);
  const transform = index === -1 ? R.append(value) : R.remove(index, 1);

  return R.set(lens, transform(values), state);
};

const toggleFilterArrayItem = (path, value, state) =>
  ({ ...toggleArrayItem(path, value, state), page: 1 });

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOAD_BANNERS_DATA_WC:
      return {
        ...state,
        loading: true
      };
    case types.LOAD_BANNERS_DATA_COMPLETED:
      return {
        ...state,
        bannerswc: action.bannerswc,
        pageSize: action.pageSize,
        total: action.total,
        loading: false,
        loaded: true
      };
    case types.SET_PAGE:
      return { ...state, page: action.page };
    case types.REMOVE_BANNER:
      return {
        ...state,
        bannerswc: state.bannerswc.filter(row => row.id !== action.id)
      };
    case types.LOAD_ONE_BANNER_COMPLETED:
      return { ...state, bannerwc: action.bannerwc };
    case types.LOAD_BANNER_TYPES_COMPLETED:
      return { ...state, bannerwcTypes: action.bannerwcTypes };
    case types.SET_DRAFT_BANNER_FIELD:
      return R.assocPath(['bannerwc', ...action.field], action.value, state);
    case types.TOGGLE_ACTIVITY_TYPE:
      return toggleFilterArrayItem(['filters', 'activity'], action.id, state);
    case types.TOGGLE_TYPE:
      return toggleFilterArrayItem(['filters', 'types'], action.id, state);
    default:
      return state;
  }
}
