import React from 'react';
import {TableRow, TableCell} from 'material-ui/Table';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import {connect} from 'react-redux'
import {withStyles} from 'material-ui/styles';
import {removeReview} from '../../../../modules/operator/reviews'


const styles = theme => ({
  message: {
    maxWidth: 550,
    whiteSpace: 'pre-wrap',
    paddingBottom: 15
  }
});


const connector = connect(
  null,
  dispatch => ({dispatch})
)

class ReviewsListRow extends React.PureComponent {

  handleClick = () => {
    if (window.confirm('Вы уверены, что хотите удалить данное сообщение?')) {
      this.props.dispatch(removeReview(this.props.review.id))
    }
  }

  render() {
    const review = this.props.review;

    return (
      <TableRow>
        <TableCell>
          {review.id}
        </TableCell>
        <TableCell>
          {review.date_create}
        </TableCell>
        <TableCell>
          {review.user_name || '-'}
        </TableCell>
        <TableCell>
          {review.user_email || '-'}
        </TableCell>
        <TableCell className={this.props.classes.message}>
          {review.message || '-'}
        </TableCell>
        <TableCell>
          <IconButton onClick={this.handleClick}>
            <Icon className="material-icons" color="accent">delete</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

export default connector(withStyles(styles)(ReviewsListRow));
