import React from 'react';
import {Block, Row} from '../../../components/SubLayout';
import TextField from 'material-ui/TextField';


const styles = {
  text: {
    width: '100%'
  }
}

class Description extends React.PureComponent {
  render() {
    return (
      <Block title="Описание">
        <Row>
          <TextField
            style={styles.text}
            multiline
            value={this.props.product.DETAIL_TEXT_STRIPPED || ''}
            onChange={this.props.setFieldValue(['DETAIL_TEXT_STRIPPED'])}
          />
        </Row>
      </Block>
    );
  }
}

export default Description;
