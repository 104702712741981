import React from 'react';
import {connect} from 'react-redux';
import TextField from 'material-ui/TextField';
import {setSearch} from '../../../../modules/operator/baskets'
const {getBasketsSearch} = require('../../../../selectors').default;



class BasketsPageSidebar extends React.PureComponent {

  handleSearchChange = e => {
    e.preventDefault();
    this.props.setSearch(e.target.value);
  };

  render() {
    return (
      <div>
        {this.renderSearchFilter()}
      </div>
    );
  }

  renderSearchFilter() {
    return <TextField
      label="Поиск"
      value={this.props.search}
      onChange={this.handleSearchChange}
    />;
  }
}


export default connect(
  state => ({
    search: getBasketsSearch(state)
  }),
  dispatch => ({
    setSearch(search) {
      dispatch(setSearch(search));
    }
  })
)(BasketsPageSidebar);
