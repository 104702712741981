import React from 'react';
import R from 'ramda';
import { connect } from 'react-redux';
import { Row, Cell, Block } from '../../../../components/SubLayout';
import TextField from 'material-ui/TextField';
import { setDraftOrderField } from '../../../../modules/orders/actions';
const { getOrderDraftOrder, getOrderDraftUser } = require('../../../../selectors').default;


const textFieldStyle = {
  fontSize: 13,
  width: '100%'
};

class UserIdentity extends React.PureComponent {
  handleCardCodeChange = e => {
    this.props.setFieldValue(['USER_DATA', 'CARD_CODE'], e.target.value);
  };

  handlePersonalMobileChange = e => {
    this.props.setFieldValue(['USER_DATA', 'PERSONAL_MOBILE'], e.target.value);
  };

  handleEmailChange = e => {
    this.props.setFieldValue(['USER_DATA', 'EMAIL'], e.target.value);
  };

  render() {
    const user = this.props.user;
    const order = this.props.order;

    return (
      <Block title="Идентификация клиента">
        <Row>
          <Cell>
            <TextField label="Баркод дисконтной карты" value={R.path(['USER_DATA', 'CARD_CODE'], order) || ''} onChange={this.handleCardCodeChange} style={textFieldStyle} />
          </Cell>
          <Cell>
            <TextField label="E-mail" value={R.path(['USER_DATA', 'EMAIL'], order) || ''} onChange={this.handleEmailChange} style={textFieldStyle} />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField label="Мобильный телефон" value={R.path(['USER_DATA', 'PERSONAL_MOBILE'], order) || ''} onChange={this.handlePersonalMobileChange} style={textFieldStyle} />
          </Cell>
          <Cell>
            <TextField label="ИД пользователя" value={user.ID || ''} style={textFieldStyle} />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField label="Скидка на заказ" value={user.TRADEX_DISCOUNT || ''} style={textFieldStyle} />
          </Cell>
          <Cell>
            <TextField label="ФИО" value={String(user.LAST_NAME || '') + ' ' + String(user.NAME || '') + ' ' + String(user.SECOND_NAME || '')} style={textFieldStyle} />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default connect(
  state => ({
    user: getOrderDraftUser(state),
    order: getOrderDraftOrder(state)
  }),
  (dispatch, ownProps) => ({
    setFieldValue(field, value) {
      dispatch(setDraftOrderField(field, value));
    }
  })
)(UserIdentity);
