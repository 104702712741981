import React from 'react';
import {Block, Row, Cell} from '../../../components/SubLayout';
import CustomSelect from '../../../components/CustomSelect';
import R from 'ramda';


const collectionTypeOptions = [
  {id: 'N', text: 'Коллекция'},
  {id: 'Y', text: 'Преколлекция'}
];

const commonOptions = [
  {id: 'N', text: 'Нет'},
  {id: 'Y', text: 'Да'}
];

class Parameters extends React.PureComponent {
  getTypeOptionsId(options, text) {
    return options && options.length && text ? options.filter(one => one.name.toString() === text.toString())[0] && options.filter(one => one.name.toString() === text.toString())[0].id : '';
  }

  getTypeOptions(ownProps) {
    if (!ownProps) {
      return [];
    }
    return R.memoize(options => options.map(
      ({id, name}) =>
        ({id, text: name})
    ))(ownProps);
  }

  createPath(prop) {
    return ['PROPERTIES', prop, 'value'];
  }

  render() {
    const product = this.props.product;
    const productProps = product.PROPERTIES || {};

    return (
      <Block title="Опции">
        <Row>
          <Cell>Стиль:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.styles)}
              value={this.getTypeOptionsId(this.props.styles, productProps.STYLE && productProps.STYLE.print_value)}
              onChange={this.props.setFieldSelect({'path': this.createPath('STYLE'), 'options': this.props.styles})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Сезон:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.collections)}
              value={this.getTypeOptionsId(this.props.collections, productProps.COLLECTION && productProps.COLLECTION.print_value)}
              onChange={this.props.setFieldSelect({'path': this.createPath('COLLECTION'), 'options': this.props.collections})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Товарная группа:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.rootSections)}
              value={parseInt(product.ROOT_SECTION_ID, 10)}
              onChange={this.props.setFieldValue(['ROOT_SECTION_ID'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Категория:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.categories)}
              value={parseInt(product.SECTION_ID, 10)}
              onChange={this.props.setFieldValue(['SECTION_ID'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Бренд:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.brands)}
              value={this.getTypeOptionsId(this.props.brands, (productProps.BRAND && productProps.BRAND.print_value) || 'Нет')}
              onChange={this.props.setFieldSelect({'path': this.createPath('BRAND'), 'options': this.props.brands})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Номер машины:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.cars)}
              value={this.getTypeOptionsId(this.props.cars, productProps.PROSPECTIVE_CAR_NUMBER && productProps.PROSPECTIVE_CAR_NUMBER.print_value)}
              onChange={this.props.setFieldSelect({'path': this.createPath('PROSPECTIVE_CAR_NUMBER'), 'options': this.props.cars})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Разрешен:</Cell>
          <Cell>
            <CustomSelect
              options={commonOptions}
              value={productProps.CACHE_ENABLED && productProps.CACHE_ENABLED.value}
              onChange={this.props.setFieldValue(this.createPath('CACHE_ENABLED'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Ожидаемый товар:</Cell>
          <Cell>
            <CustomSelect
              options={commonOptions}
              value={productProps.PROSPECTIVE && productProps.PROSPECTIVE.value}
              onChange={this.props.setFieldValue(this.createPath('PROSPECTIVE'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Страна-производитель:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.countries)}
              value={this.getTypeOptionsId(this.props.countries, (productProps.COUNTRY && productProps.COUNTRY.print_value) || 'Нет')}
              onChange={this.props.setFieldSelect({'path': this.createPath('COUNTRY'), 'options': this.props.countries})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Страна бренда:</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions(this.props.countries)}
              value={this.getTypeOptionsId(this.props.countries, (productProps.BRAND_COUNTRY && productProps.BRAND_COUNTRY.print_value) || 'Нет')}
              onChange={this.props.setFieldSelect({'path': this.createPath('BRAND_COUNTRY'), 'options': this.props.countries})}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Бестселлер:</Cell>
          <Cell>
            <CustomSelect
              options={commonOptions}
              value={productProps.BEST_SELLER && productProps.BEST_SELLER.value}
              onChange={this.props.setFieldValue(this.createPath('BEST_SELLER'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Рекомендуемый:</Cell>
          <Cell>
            <CustomSelect
              options={commonOptions}
              value={productProps.RECOMMENDED && productProps.RECOMMENDED.value}
              onChange={this.props.setFieldValue(this.createPath('RECOMMENDED'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Тип коллекции:</Cell>
          <Cell>
            <CustomSelect
              options={collectionTypeOptions}
              value={productProps.COLLECTION_TYPE && productProps.COLLECTION_TYPE.print_value}
              onChange={this.props.setFieldValue(this.createPath('COLLECTION_TYPE'))}
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default Parameters;
