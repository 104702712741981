import {put, takeEvery, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
const Cookies = require('js-cookie');

const SIGNIN_START = 'signin/START';
const SIGNIN_COMPLETED = 'signin/COMPLETED';
const SIGNOUT_START = 'signout/START';
const SIGNOUT_COMPLETED = 'signout/COMPLETED';


const initialState = {
  success: undefined
};

export function reducer(state = initialState, action) {
  if (action.type === SIGNIN_START) {
    return {...state, success: undefined};
  }
  if (action.type === SIGNIN_COMPLETED) {
    return {...state, success: action.success};
  }
  if (action.type === SIGNOUT_START) {
    return {...state, success: undefined};
  }
  return state;
}

export const setAuthResult = success => ({
  type: SIGNIN_COMPLETED,
  success
})

export const signin = (history, login, password) => ({
  type: SIGNIN_START,
  history,
  login,
  password
});

export const signout = (history) => ({
  type: SIGNOUT_START,
  history
});

export const signoutCompleted = () => ({type: SIGNOUT_COMPLETED});


function* signinAsync({history, login, password}) {
  const result = yield axios({
    url: '/api/core/login/index',
    method: 'post',
    withCredentials: true,
    data: qs.stringify({
      username: login,
      password
    })
  });

  yield put(setAuthResult(result.data.success));

  if (result.data.success) {
    history.push('/');
  }
}

function* signoutAsync({history}) {
  Cookies.remove('sessionHash');
  history.push('/login');

  yield put(signoutCompleted());
}

export function* saga() {
  yield takeEvery(SIGNIN_START, signinAsync);
  yield takeLatest(SIGNOUT_START, signoutAsync);
}


export const selectors = {
  isAuthenticated: state => state.success
}
