import {put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

const MENU_LOAD = 'menu/LOAD';
const MENU_LOAD_COMPLETED = 'menu/LOAD_COMPLETED';


const initialState = {
  items: []
};

export function reducer(state = initialState, action) {
  if (action.type === MENU_LOAD_COMPLETED) {
    return {...state, items: action.items};
  }
  return state;
}

export const loadMenu = () => ({
  type: MENU_LOAD
});

function* loadMenuAsync() {
  const result = yield axios({
    url: '/api/core/menu/list',
    withCredentials: true
  });

  if (!Array.isArray(result.data)) {
    yield put({type: MENU_LOAD_COMPLETED, items: []});
    return;
  }

  yield put({type: MENU_LOAD_COMPLETED, items: result.data});
}

export function* saga() {
  yield takeEvery(MENU_LOAD, loadMenuAsync);
}
