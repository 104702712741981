import R from 'ramda';
import moment from 'moment';

const types = require('./types');

const initialDraft = {
    loading: false,
    user: {},
    order: {},
    confirmationEmail: {
        processing: false,
        processed: false
    },
    confirmationSms: {
        processing: false,
        processed: false
    },
    ecardSms: {
        processing: false,
        processed: false
    },
    delivSms: {
        processing: false,
        processed: false
    },
    pricesProcessing: {
        processing: false,
        processed: false
    },
    paymentUrl: {
        sending: false,
        sent: false
    },
    paymentSms: {
        sending: false,
        sent: false
    },
    ordersExport: {
        sending: false,
        sent: false,
        link: null
    },
    productsSearch: '',
    products: []
};

const initialInputFilters = {
    search: '',
    article: '',
    size: '',
    sources: [],
    types: [],
    aggregated: [],
    statuses: [],
    sourceStockId: null,
    deliveryFrom: null,
    deliveryTo: null,
    createFrom: moment().subtract(3, 'months').toDate(),
    createTo: null
};

const initialState = {
    loading: false,
    loaded: false,
    error: '',
    ids: [],
    byIds: {},
    filters: {},
    sourceStocks: [],
    sources: {
        ids: [],
        byIds: {}
    },
    lastId: 0,
    statuses: [],
    types: [],
    aggregated: [],
    basketStatuses: [],
    deliveries: [],
    paySystems: [],
    metroStations: [],
    cities: [],
    countries: [],
    total: 0,
    page: 1,
    pageSize: 60,
    inputFilters: initialInputFilters,
    draft: initialDraft
};

const shallowCache = (prev, next) =>
    R.pipe(
        R.keys,
        R.map(key => [
            key,
            R.equals(prev[key], next[key]) ? prev[key] : next[key]
        ]),
        R.fromPairs
    )(next);

const toggleArrayItem = (path, value, state) => {
    const lens = R.lensPath(path);

    let values = R.view(lens, state) || [];
    const index = R.indexOf(value, values);
    const transform = index === -1 ? R.append(value) : R.remove(index, 1);

    return R.set(lens, transform(values), state);
};

const combineReducersMerging = reducers => function (state, action) {
    const nextState = {};
    let hasChanged = false;
    Object.keys(reducers).forEach(key => {
        nextState[key] = reducers[key](state[key], action);
        hasChanged = hasChanged || nextState[key] !== state[key];
    });
    return hasChanged ? { ...state, ...nextState } : state;
};

const oneBasketReducer = (state, action) => {
    switch (action.type) {
        case types.SET_BASKET_STATUS_ID:
            return {
                ...state,
                STATUS_ID: action.statusId,
                status_id: action.statusId
            };
        case types.SET_BASKET_QTY:
            return {
                ...state,
                QUANTITY: action.QUANTITY,
            };
        case types.SET_BASKET_PROMOKOD:
            return {
                ...state,
                PROMOKOD: action.PROMOKOD,
            };
        case types.SET_ADMIN_COMMENT:
            return {
                ...state,
                ADMIN_COMMENT: action.ADMIN_COMMENT
            };
        case types.SET_BASKET_GOODS_MANAGER_COMMENT:
            return {
                ...state,
                GOODS_MANAGER_COMMENT: action.value
            };
        case types.REMOVE_BASKET:
            return {
                ...state,
                QUANTITY: 0,
                removed: true
            };
        case types.SET_BASKET_SOLD:
            return {
                ...state,
                IS_SOLD: action.is_sold ? 1 : 0,
                is_sold: action.is_sold
            };
        default:
            return state;
    }
};

const draft = (state, action) => {
    switch (action.type) {
        case types.LOAD_ONE_ORDER:
            return { ...state, loading: true };
        case types.SAVE_ORDER:
            return { ...state, error: '' };
        case types.SAVE_ORDER_ERROR:
            return { ...state, error: action.error };
        case types.LOAD_USER_COMPLETED:
            state = { ...state, user: action.user };
            return R.assocPath(['order', 'USER_DATA'], action.user, state);
        case types.LOAD_ONE_ORDER_COMPLETED:
            return { ...state, order: action.order };
        case types.LOAD_ONE_ORDER_COMPLETED_FULLY:
            return { ...state, loading: false };
        case types.SET_BASKET_STATUS_ID:
        case types.SET_BASKET_QTY:
        case types.SET_BASKET_PROMOKOD:
        case types.SET_ADMIN_COMMENT:
        case types.SET_BASKET_GOODS_MANAGER_COMMENT:
        case types.REMOVE_BASKET:
        case types.SET_BASKET_SOLD:
            let index = action.index;
            const activeBasket = state.order.baskets.filter(one => !one.removed)[index];
            index = state.order.baskets.indexOf(activeBasket);
            const nextIndexState = oneBasketReducer(state.order.baskets[index], action);
            if (nextIndexState === state.order.baskets[index]) {
                return state;
            }
            const nextBaskets = R.update(index, nextIndexState, state.order.baskets);
            return R.assocPath(['order', 'baskets'], nextBaskets, state);
        case types.CALC_PRICES:
            return R.assoc('pricesProcessing', { processing: true, processed: false }, state);
        case types.CALC_PRICES_COMPLETED:
            return R.assoc('pricesProcessing', { processing: false, processed: true }, state);
        case types.CALC_PRICES_COMPLETED_FULLY:
            return R.assoc('pricesProcessing', { processing: false, processed: false }, state);
        case types.SEND_CONFIRMATION_EMAIL:
            return R.assocPath(['confirmationEmail', 'processing'], true, state);
        case types.SEND_CONFIRMATION_EMAIL_COMPLETED:
            return R.assoc('confirmationEmail', {
                processed: true,
                processing: false
            }, state);
        case types.SEND_CONFIRMATION_EMAIL_COMPLETED_FULLY:
            return R.assocPath(['confirmationEmail', 'processed'], false, state);
        case types.SEND_CONFIRMATION_SMS:
            return R.assocPath(['confirmationSms', 'processing'], true, state);
        case types.SEND_CONFIRMATION_SMS_COMPLETED:
            return R.assoc('confirmationSms', {
                processed: true,
                processing: false
            }, state);
        case types.SEND_CONFIRMATION_SMS_COMPLETED_FULLY:
            return R.assocPath(['confirmationSms', 'processed'], false, state);
        case types.SEND_PAYMENT_URL:
            return R.assocPath(['paymentUrl', 'sending'], true, state);
        case types.SEND_PAYMENT_URL_COMPLETED:
            return R.assoc('paymentUrl', {
                sending: false,
                sent: true
            }, state);
        case types.SEND_PAYMENT_URL_COMPLETED_FULLY:
            return R.assocPath(['paymentUrl', 'sent'], false, state);
        case types.SEND_PAYMENT_SMS:
            return R.assocPath(['paymentSms', 'sending'], true, state);
        case types.SEND_PAYMENT_SMS_COMPLETED:
            return R.assoc('paymentSms', {
                sending: false,
                sent: true
            }, state);
        case types.SEND_PAYMENT_SMS_COMPLETED_FULLY:
            return R.assocPath(['paymentSms', 'sent'], false, state);
        case types.ORDERS_EXPORT:
            return R.assoc('ordersExport', {
                sending: false,
                sent: false
            }, state);
        case types.ORDERS_EXPORT_COMPLETED:
            return R.assoc('ordersExport', {
                sending: true,
                sent: false,
                link: action.link
            }, state);
        case types.ORDERS_EXPORT_COMPLETED_FULLY:
            return R.assoc('ordersExport', {
                sending: false,
                sent: true,
                link: action.link
            }, state);
        case types.ORDERS_EXPORT_SET_LINK:
            return R.assocPath(['ordersExport', 'link'], action.link, state);
        case types.ORDERS_EXPORT_UNSET_LINK:
            return R.assoc('ordersExport', {
                sending: false,
                sent: false,
                link: action.link
            }, state);
        case types.SEND_ECARD_SMS:
            return R.assocPath(['ecardSms', 'processing'], true, state);
        case types.SEND_ECARD_SMS_COMPLETED:
            return R.assoc('ecardSms', {
                processed: true,
                processing: false
            }, state);
        case types.SEND_ECARD_SMS_COMPLETED_FULLY:
            return R.assocPath(['ecardSms', 'processed'], false, state);
        case types.SEND_DELIV_SMS:
            return R.assocPath(['delivSms', 'processing'], true, state);
        case types.SEND_DELIV_SMS_COMPLETED:
            return R.assoc('delivSms', {
                processed: true,
                processing: false
            }, state);
        case types.SEND_DELIV_SMS_COMPLETED_FULLY:
            return R.assocPath(['delivSms', 'processed'], false, state);
        case types.SET_DRAFT_ORDER_FIELD:
            return R.assocPath(['order', ...action.field], action.value, state);
        case types.SET_DRAFT_PRODUCTS_SEARCH:
            return R.assocPath(['productsSearch'], action.search, state);
        case types.SEARCH_PRODUCTS_COMPLETED:
            return R.assocPath(['products'], action.products || [], state);
        case types.ADD_NEW_BASKETS_TO_DRAFT:
            return R.assocPath(
                ['order', 'baskets'],
                R.concat(state.order.baskets, action.baskets),
                state
            );
        default:
            return state;
    }
};

const inputFilters = (state, action) => {
    switch (action.type) {
        case types.SET_SEARCH:
            return { ...state, search: action.search };
        case types.SET_SEARCH_ARTICLE:
            return { ...state, article: action.article };
        case types.SET_SEARCH_SIZE:
            return { ...state, size: action.size };
        case types.SET_DATE_DELIVERY_FROM:
            return { ...state, deliveryFrom: action.date };
        case types.SET_DATE_DELIVERY_TO:
            return { ...state, deliveryTo: action.date };
        case types.SET_DATE_CREATE_FROM:
            return { ...state, createFrom: action.date };
        case types.SET_DATE_CREATE_TO:
            return { ...state, createTo: action.date };
        case types.SET_SOURCE_STOCK:
            return { ...state, sourceStockId: action.id };
        case types.TOGGLE_SOURCE:
            return toggleArrayItem(['sources'], action.id, state);
        case types.TOGGLE_TYPE:
            return toggleArrayItem(['types'], action.id, state);
        case types.TOGGLE_AGGREGATED:
            return toggleArrayItem(['aggregated'], action.value, state);
        case types.TOGGLE_STATUS:
            return toggleArrayItem(['statuses'], action.id, state);
        default:
            return state;
    }
};


const subReducer = combineReducersMerging({ draft, inputFilters });

const normalizeByProp = (prop, list) => ({
    ids: R.map(R.prop(prop), list),
    byIds: R.indexBy(R.prop(prop), list)
});
const normalize = list => normalizeByProp('id', list || []);

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LAST_ORDER_ID_COMPLETED:
            return { ...state, lastId: action.lastId };
        case types.LOAD_BASKET_STATUSES_COMPLETED:
            return { ...state, basketStatuses: action.items };
        case types.LOAD_ORDER_STATUSES_COMPLETED:
            return { ...state, statuses: normalize(action.items) };
        case types.LOAD_DELIVERIES_COMPLETED:
            return { ...state, deliveries: action.items };
        case types.LOAD_PAY_SYSTEMS_COMPLETED:
            return { ...state, paySystems: action.items };
        case types.LOAD_METRO_STATIONS_COMPLETED:
            return { ...state, metroStations: action.items };
        case types.LOAD_CITIES_COMPLETED:
            return { ...state, cities: action.items };
        case types.LOAD_COUNTRIES_COMPLETED:
            return { ...state, countries: action.items };
        case types.LOAD_ORDERS:
            return { ...state, loading: true };
        case types.SET_PAGE:
            return { ...state, page: action.page };
        case types.SAVE_ORDER_COMPLETED:
            return R.assocPath(['byIds', action.order.ID], action.order, state);
        case types.LOAD_ORDERS_COMPLETED:

            return shallowCache(state, {
                ...state,
                loading: false,
                loaded: true,
                ...normalizeByProp('ID', action.items),
                sources: normalize(action.sources),
                types: normalize(action.types),
                aggregated: normalizeByProp('value', action.aggregated),
                statuses: normalize(action.statuses),
                sourceStocks: normalize(action.sourceStocks),
                total: action.total
            });

        default:
            return subReducer(state, action);
    }
}