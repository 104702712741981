import React from 'react';
import {TableRow} from 'material-ui/Table';
import {default as TableCell} from './OrdersTableCell';


class OrdersListHeaderRow extends React.PureComponent {
  firstHeaderColumnStyle = {
    width: 30
  };

  secondCellStyle = {
    width: 130
  };

  thirdCellStyle = {
    width: 100
  };

  render() {
    return (
      <TableRow>
        <TableCell style={this.firstHeaderColumnStyle}>
        </TableCell>
        <TableCell style={this.secondCellStyle}>
          №<br/>
          Кол-во товаров<br/>
          Изменение<br/>
          Создание
        </TableCell>
        <TableCell style={this.thirdCellStyle}>
          Товары<br/>
          Скидка<br/>
          Доставка<br/>
          Итоговая сумма
        </TableCell>
        <TableCell>
          Доставка<br/>
          Обратный звонок
        </TableCell>
        <TableCell>
          Коммент
        </TableCell>
        <TableCell>
          Адрес
        </TableCell>
        <TableCell>
          Фио<br/>
          Номер телефона
        </TableCell>
        <TableCell>
					<a data-code="STATUS_ID">Статус</a><br/>
					Готовность<br/>
					Тип<br/>
					Источник
        </TableCell>
        <TableCell>
          Действие
        </TableCell>
      </TableRow>
    );
  }
}

export default OrdersListHeaderRow;
