const types = require("./types");

export const loadProducts = () => ({
  type: types.LOAD_PRODUCTS,
});

export const loadOneProduct = (id) => ({
  type: types.LOAD_ONE_PRODUCT,
  id,
});

export const loadOneProductRemains = (id) => ({
  type: types.LOAD_ONE_PRODUCT_REMAINS,
  id,
});

export const setProductsSearch = (search) => ({
  type: types.SET_SEARCH,
  search,
});

export const setRemainsEnabled = (productId, stockId, sizeName) => ({
  type: types.SET_REMAINS_ENABLED,
  productId,
  stockId,
  sizeName,
});

export const setRemainsDisabled = (productId, stockId, sizeName) => ({
  type: types.SET_REMAINS_DISABLED,
  productId,
  stockId,
  sizeName,
});

export const setOtzyv = (productId, text) => ({
  type: types.SET_OTZYV,
  productId,
  text,
});

export const setDraftProductField = (field, value) => ({
  type: types.SET_DRAFT_PRODUCT_FIELD,
  field,
  value,
});

export const uploadProductImage = (data, id) => ({
  type: types.UPLOAD_PRODUCT_IMAGE,
  data,
  id,
});

export const saveProduct = (history) => ({
  type: types.SAVE_PRODUCT,
  history,
});

export const loadProductStyles = () => ({
  type: types.LOAD_PRODUCT_STYLES,
});

export const loadProductCollections = () => ({
  type: types.LOAD_PRODUCT_COLLECTIONS,
});

export const loadProductRootSections = () => ({
  type: types.LOAD_PRODUCT_ROOTSECTIONS,
});

export const loadProductCategories = (id) => ({
  type: types.LOAD_PRODUCT_CATEGORIES,
  id,
});

export const loadProductBrands = () => ({
  type: types.LOAD_PRODUCT_BRANDS,
});

export const loadProductCars = () => ({
  type: types.LOAD_PRODUCT_CARS,
});

export const loadProductCountries = () => ({
  type: types.LOAD_PRODUCT_COUNTRIES,
});
