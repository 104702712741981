import React from 'react';
import {Block, Row, Cell} from '../../../components/SubLayout';
import FormControl from 'material-ui/Form/FormControl';
import Input from 'material-ui/Input';


const styles = {
  img: {
    maxWidth: 300,
    maxHeight: 300
  }
}

class Image extends React.PureComponent {
  handleUploadImage = event => {
    const data = new FormData();
    data.append('main_photo', event.target.files[0]);
    data.append('id', this.props.product.ID);
    this.props.uploadImage(data);
  }

  render() {
    const product = this.props.product;

    return (
      <Block title="Основное фото">
        <Row>
          <Cell>
            <FormControl onChange={this.handleUploadImage}>
              <Input type="file" accept="image/jpeg, image/png"/>
            </FormControl>
          </Cell>
          <Cell>
            <img
              style={styles.img}
              alt="Фото"
              src={(product.PICTURE_DATA && product.PICTURE_DATA.big) || ''}
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default Image;
