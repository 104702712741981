import React from 'react';
import PropTypes from 'prop-types';
import Button from 'material-ui/Button';
import R from 'ramda';


const styles = {
  root: {
    marginBottom: 10
  },
  totalInfo: {
    verticalAlign: 'middle',
    marginLeft: 15,
    whiteSpace: 'nowrap'
  },
  pageButton: {
    minWidth: 40
  }
};

class Pagination extends React.PureComponent {
  memoizedOnChange = R.memoize(n => () => {
    this.props.onChange(n);
  });

  render() {
    const page = this.props.current;
    const count = this.count();

    return (
      <div style={styles.root}>
        <Button color="primary" onClick={this.memoizedOnChange(1)} disabled={page === 1}>Первая</Button>
        {R.map(number => {
          return (
            <Button
              key={number}
              color={number === page ? 'accent' : 'primary'}
              onClick={this.memoizedOnChange(number)}
              style={styles.pageButton}
            >{number}</Button>
          );
        }, this.pagesToDisplay())}
        <Button color="primary" onClick={this.memoizedOnChange(count)} disabled={page === count}>Последняя</Button>
        <span style={styles.totalInfo}>
          {this.props.pageSize * (page - 1) + 1} - {Math.min(this.props.pageSize * page, this.props.total)} из {this.props.total}
        </span>
      </div>
    );
  }

  count() {
    return this.props.pageSize ? Math.ceil(this.props.total / this.props.pageSize) : 1;
  }

  pagesToDisplay() {
    const page = this.props.current;
    const count = this.count();

    const maxCount = 5;
    const pages = R.range(1, count + 1);

    const side = (maxCount - 1) / 2;

    let leftDiff = page - 1 - Math.max(0, page - side - 1);
    let rightDiff = Math.min(count, page + side) - page;

    let remain = maxCount - 1 - rightDiff - leftDiff;

    if (leftDiff < rightDiff) {
      rightDiff += remain;
    } else if (leftDiff > rightDiff) {
      leftDiff += remain;
    }

    const pageIndex = page - 1;

    return R.slice(
      Math.max(pageIndex - leftDiff, 0),
      pageIndex + rightDiff + 1,
      pages
    );
  }
}

Pagination.PropTypes = {
  total: PropTypes.number,
  pageSize: PropTypes.number,
  current: PropTypes.number,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
