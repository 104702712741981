import React from 'react';
import Layout from '../../../components/Layout';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Table, {TableHead, TableBody} from 'material-ui/Table';
import CatalogListHeaderRow from '../components/CatalogListHeaderRow';
import CatalogListRow from '../components/CatalogListRow';
import CatalogListSidebar from './CatalogListSidebar';
import {loadProducts} from '../../../modules/products/index';

const {getProductsList} = require('../../../selectors').default;


class CatalogListPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadProducts();
  }

  render() {
    return (
      <Layout
        content={this.renderContent()}
        sidebar={<CatalogListSidebar/>}
      />
    );
  }

  renderContent() {
    return (
      <div>
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    return (
      <Table>
        <TableHead>
          <CatalogListHeaderRow/>
        </TableHead>
        <TableBody>
          {this.props.products.map((product, index) =>
            <CatalogListRow
              key={index}
              product={product}
              onOpen={this.props.handleProductRemainsOpen}
            />
          )}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(connect(
  state => ({
    products: getProductsList(state)
  }),
  (dispatch, ownProps) => ({
    handleProductRemainsOpen(product) {
      ownProps.history.push(`/catalog/products/${product.ID}`);
    },
    loadProducts() {
      dispatch(loadProducts());
    }
  })
)(CatalogListPage));
