import React from 'react';
import { connect } from 'react-redux';
import { Row, Cell, Block } from '../../../../components/SubLayout';
import TextField from 'material-ui/TextField';
import CustomSelect from '../../../../components/CustomSelect';
import SmartFab from '../../../../components/SmartFab';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import R from 'ramda';
import { sendPaymentUrl, viewPaymentUrl, sendPaymentSms } from '../../../../modules/orders/actions';
const { getDeliveries, getPaySystems, getOrderDraftPaymentUrl, getOrderDraftPaymentSms } = require('../../../../selectors').default;


const deliveryTimeRangeContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

const textFieldStyle = {
  fontSize: 13,
  width: '100%'
};

const memoizedOptions = R.memoize(
  deliveries => deliveries.map(
    ({ id, name }) =>
      ({ id, text: name })
  )
);


class DeliveryParams extends React.PureComponent {
  render() {
    const order = this.props.order;
    const showmodules = order.showmodules ? order.showmodules : {};
    const deliveryparams = showmodules.deliveryparams;
    return (
      deliveryparams ? this.render2() : this.render1()
    )
  }
  render2() {
    const order = this.props.order;
    const orderProps = order.props || {};
    const deliveryname = order.showmodules && order.showmodules.deliveryparams && order.showmodules.deliveryparams.deliveryname ? order.showmodules.deliveryparams.deliveryname : "Дата доставки";
    return (
      <Block title="Параметры доставки">
        <Row>
          <Cell>
            <TextField
              label={deliveryname}
              value={order.DELIVERY_DOC_DATE || ''}
              onChange={this.props.setFieldValue(['DELIVERY_DOC_DATE'])}
              style={textFieldStyle}
              type="date"
            />
          </Cell>
          <Cell style={deliveryTimeRangeContainerStyle}>
            <TextField
              label="От"
              value={orderProps.DELIVERY_REAL_TIME_FROM || ' '}
              onChange={this.props.setFieldValue(['props', 'DELIVERY_REAL_TIME_FROM'])}
              style={textFieldStyle}
              type="time"
            />
            <TextField
              label="До"
              value={orderProps.DELIVERY_REAL_TIME_TO || ' '}
              onChange={this.props.setFieldValue(['props', 'DELIVERY_REAL_TIME_TO'])}
              style={textFieldStyle}
              type="time"
            />
          </Cell>
        </Row>
      </Block>
    );
  }
  render1() {
    const order = this.props.order;
    const orderProps = order.props || {};
    return (
      <Block title="Параметры доставки">
        <Row>
          <Cell>
            <CustomSelect
              label="Способ оплаты"
              options={memoizedOptions(this.props.paySystems)}
              value={parseInt(order.PAY_SYSTEM_ID, 10)}
              onChange={this.props.setFieldValue(['PAY_SYSTEM_ID'])}
            />
          </Cell>
          <Cell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {parseInt(order.PAY_SYSTEM_ID, 10) === 2 &&
                <SmartFab
                  onClick={this.props.sendPaymentUrl}
                  processing={this.props.paymentUrl.sending}
                  processed={this.props.paymentUrl.sent}
                  icon={'payment'}
                />
              }
              {parseInt(order.PAY_SYSTEM_ID, 10) === 2 &&
                <IconButton onClick={this.props.viewPaymentUrl}>
                  <Icon className="material-icons">link</Icon>
                </IconButton>
              }
              {parseInt(order.PAY_SYSTEM_ID, 10) === 2 &&
                <SmartFab
                  onClick={this.props.sendPaymentSms}
                  processing={this.props.paymentSms.processing}
                  processed={this.props.paymentSms.processed}
                  icon={'sms'}
                />
              }
            </div>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <CustomSelect
              label="Тип доставки"
              options={memoizedOptions(this.props.deliveries)}
              value={order.DELIVERY_ID}
              onChange={this.props.setFieldValue(['DELIVERY_ID'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <CustomSelect
              label="Юнит доставки"
              options={order.DELIVERY_UNITS_ARRAY}
              value={orderProps.DELIVERY_UNIT}
              onChange={this.props.setFieldValue(['props', 'DELIVERY_UNIT'])}
            />
          </Cell>
          <Cell>
            <TextField
              label="Стоимость доставки" value={order.PRICE_DELIVERY || ''}
              onChange={this.props.setFieldValue(['PRICE_DELIVERY'])}
              style={textFieldStyle}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField
              label="Дата доставки"
              value={order.DELIVERY_DOC_DATE || ''}
              onChange={this.props.setFieldValue(['DELIVERY_DOC_DATE'])}
              style={textFieldStyle}
              type="date"
            />
          </Cell>
          <Cell style={deliveryTimeRangeContainerStyle}>
            <TextField
              label="От"
              value={orderProps.DELIVERY_REAL_TIME_FROM || ' '}
              onChange={this.props.setFieldValue(['props', 'DELIVERY_REAL_TIME_FROM'])}
              style={textFieldStyle}
              type="time"
            />
            <TextField
              label="До"
              value={orderProps.DELIVERY_REAL_TIME_TO || ' '}
              onChange={this.props.setFieldValue(['props', 'DELIVERY_REAL_TIME_TO'])}
              style={textFieldStyle}
              type="time"
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}


export default connect(
  state => ({
    deliveries: getDeliveries(state),
    paySystems: getPaySystems(state),
    paymentUrl: getOrderDraftPaymentUrl(state),
    paymentSms: getOrderDraftPaymentSms(state),
  }),
  (dispatch, ownProps) => ({
    sendPaymentUrl() {
      dispatch(sendPaymentUrl(ownProps.order.ID));
    },
    viewPaymentUrl() {
      dispatch(viewPaymentUrl(ownProps.order.ID));
    },
    sendPaymentSms() {
      dispatch(sendPaymentSms(ownProps.order.ID));
    }
  })
)(DeliveryParams);
