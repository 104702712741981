import React from 'react';
import Dialog, {DialogContent, DialogTitle} from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import blue from 'material-ui/colors/blue';
import ProductsTable from './ProductsTable';
import ProductsSearch from './ProductsSearch';



const titleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const closeIconStyle = {
  color: blue[500]
};

const Title = props => (
  <div style={titleStyle}>
    Добавление товаров к заказу
    <IconButton onClick={props.onClose}><Icon className="material-icons" style={closeIconStyle}>close</Icon></IconButton>
  </div>
);


class ProductsZone extends React.Component {
  handleEntering = () => {
  };

  render() {
    return (
      <Dialog fullScreen onEntering={this.handleEntering} open={this.props.open}>
        <DialogTitle><Title onClose={this.props.onRequestClose} /></DialogTitle>
        <DialogContent>
          <ProductsSearch />
          <ProductsTable />
        </DialogContent>
      </Dialog>
    );
  }
}

export default ProductsZone;
