import React from 'react';
import Layout from '../../components/Layout';
import {connect} from 'react-redux';
import {withStyles} from 'material-ui/styles';
import {exportProducts} from '../../modules/exporting';
import SmartButton from '../../components/SmartButton';


const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing.unit,
  },
  link: {
    marginTop: 10
  }
});


class CatalogExportPage extends React.Component {
  render() {
    return (
      <Layout
        content={this.renderContent()}
      />
    );
  }

  renderContent() {
    const {classes} = this.props;
    return (
      <div className={classes.container}>
        <SmartButton
          className={classes.button}
          onClick={this.props.exportProducts}
          processing={this.props.processing}
          processed={this.props.processed}
        >
          Экспорт товаров
        </SmartButton>

        {this.props.link &&
          <a href={this.props.link} className={classes.link}>Скачать</a>
        }
      </div>
    )
  }
}



export default connect(
  state => state.exporting,
  (dispatch, ownProps) => ({
    exportProducts() {
      dispatch(exportProducts());
    }
  })
)(withStyles(styles)(CatalogExportPage));
