import React from 'react';
import { CircularProgress } from 'material-ui/Progress';
import Button from 'material-ui/Button';
import green from 'material-ui/colors/green';
import {withStyles} from 'material-ui/styles';


const styles = {
  progress: {
    color: green[500],
    position: 'absolute',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};


class SmartButton extends React.PureComponent {

  render() {
    const {onClick, processing, classes, children} = this.props;

    return (
      <Button color="primary" raised onClick={onClick}>
        {children}
        {processing &&
          <CircularProgress className={classes.progress}/>
        }
      </Button>
    );
  }
}

export default withStyles(styles)(SmartButton);
