import React from 'react';
import {withStyles} from 'material-ui/styles';
import SmartFab from '../components/SmartFab';
import Loader from '../components/Loader';
import Layout from '../components/Layout';


const styles = theme => ({
  top: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center'
  },
  notFound: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    marginTop: -10
  },
  refresherContainer: {
    flex: 'none',
    margin: '0 20px'
  }
});


export default (Component, Sidebar) => withStyles(styles)(class extends React.Component {

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    if (!this.props.loaded && !this.props.loading) {
      return null;
    }
    return (
      <Layout
        content={this.props.loading && this.props.items.length === 0 ? <Loader/> : this.renderContent()}
        sidebar={this.props.loaded && Sidebar ? <Sidebar /> : null}
      />
    );
  }

  renderContent() {
    if (this.props.items.length === 0) {
      return (
        <div className={this.props.classes.notFound}>
          Данные не найдены
        </div>
      );
    }
    return (
      <div>
        <div className={this.props.classes.top}>
          {this.renderRefresher()}
        </div>
        {this.renderData()}
      </div>
    )
  }

  renderRefresher() {
    return (
      <div className={this.props.classes.refresherContainer}>
        <SmartFab processing={this.props.loading} onClick={this.props.loadData} icon={'refresh'}/>
      </div>
    );
  }

  renderData() {
    return <Component items={this.props.items} />
  }
})

