import React from 'react';
import {connect} from 'react-redux';
import Table, {TableHead, TableBody, TableRow} from 'material-ui/Table';
import {default as TableCell} from './OrdersTableCell';
import ProductRow from './ProductRow';
const {getOrderDraftProducts} = require('../../../selectors').default;


const ProductsTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>ИД</TableCell>
      <TableCell>Фото</TableCell>
      <TableCell>
        Артикул<br />
        Бренд<br />
        Сезон
      </TableCell>
      <TableCell>Цвет</TableCell>
      <TableCell>Раздел</TableCell>
      <TableCell>Размер (остатки)</TableCell>
    </TableRow>
  </TableHead>
);


class ProductsTable extends React.Component {
  render() {
		const products = this.props.products;

  	if (products.length === 0) {
  		return <div/>;
		}

    return (
      <Table>
        <ProductsTableHeader/>
        <TableBody>
          {products.map(product => <ProductRow key={product.ID} product={product} />)}
        </TableBody>
      </Table>
    );
  }
}

export default connect(
  state => ({
    products: getOrderDraftProducts(state)
  })
)(ProductsTable);
