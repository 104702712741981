import R from 'ramda';


export const getBannersList = R.path(['banners']);

export const getBannersTotal = state => parseInt(R.path(['total'])(state), 10);
export const getBannersPage = state => parseInt(R.path(['page'])(state), 10);
export const getBannersPageSize = state => parseInt(R.path(['pageSize'])(state), 10);
export const getBannersLoading = state => state.loading;
export const getBannersLoaded = state => state.loaded;

export const getBanner = R.path(['banner']);

export const getBannerTypes = R.path(['bannerTypes']);

export const getBannersActivityTypesSelected = R.path(['filters', 'activity']);
export const getBannersTypesSelected = R.path(['filters', 'types']);
