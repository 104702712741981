import {put, all, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import R from 'ramda';

const LOAD_SUBSCRIPTIONS = 'subscriptions/LOAD_SUBSCRIPTIONS'
const LOAD_SUBSCRIPTIONS_COMPLETED = 'subscriptions/LOAD_SUBSCRIPTIONS_COMPLETED'


const initialState = {
  items: [],
  loading: false,
  loaded: false
}

export function reducer (state = initialState, action) {
  switch(action.type) {
    case LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUBSCRIPTIONS_COMPLETED:
      return {
        ...state,
        items: action.items,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
}

export const loadSubscriptions = () => ({
  type: LOAD_SUBSCRIPTIONS
});


function* loadSubscriptionsSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/marketer/subscription',
  });

  yield put({
    type: LOAD_SUBSCRIPTIONS_COMPLETED,
    items: result.data.items
  });
}

export function* saga() {
  yield all([
    takeLatest(LOAD_SUBSCRIPTIONS, loadSubscriptionsSaga)
  ]);
}

export const selectors = {
  getSubscriptionsList: R.path(['items']),
  getSubscriptionsLoading: state => state.loading,
  getSubscriptionsLoaded: state => state.loaded,
}
