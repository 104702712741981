import React from 'react';
import Grid from 'material-ui/Grid';
import Paper from 'material-ui/Paper';


export const Container = props => <Grid container spacing={24}>{props.children}</Grid>


export const Row = props => <Grid container spacing={0}>{props.children}</Grid>;


const cellStyle = {padding: '5px'};

export const Cell = props => <Grid style={props.style ? {...cellStyle, ...props.style} : cellStyle} item xs={12} sm={props.size || 6}>{props.children}</Grid>


const headerStyle = {padding: '5px'};

export const Block = props =>
  <Grid item xs={12} sm={12} md={props.size || 6}>
    <h4 style={headerStyle}>{props.title}</h4>
    <Paper>{props.children}</Paper>
  </Grid>
;
