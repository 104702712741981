import R from 'ramda';


export const getBannersListWc = R.path(['bannerswc']);

export const getBannersTotalWc = state => parseInt(R.path(['total'])(state), 10);
export const getBannersPageWc = state => parseInt(R.path(['page'])(state), 10);
export const getBannersPageSizeWc = state => parseInt(R.path(['pageSize'])(state), 10);
export const getBannersLoadingWc = state => state.loading;
export const getBannersLoadedWc = state => state.loaded;

export const getBannerWc = R.path(['bannerwc']);

export const getBannerTypesWc = R.path(['bannerwcTypes']);

export const getBannersActivityTypesSelectedWc = R.path(['filters', 'activity']);
export const getBannersTypesSelectedWc = R.path(['filters', 'types']);
