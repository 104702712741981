import React from 'react';
import {withRouter} from 'react-router';
import Layout from '../../../../components/Layout';
import {connect} from 'react-redux';
import Table, {TableHead, TableBody, TableRow, TableCell} from 'material-ui/Table';
import {loadSubscriptions} from '../../../../modules/marketer/subscriptions';
import Loader from '../../../../components/Loader';
const {
  getSubscriptionsList,
  getSubscriptionsLoading,
  getSubscriptionsLoaded
} = require('../../../../selectors').default;


const styles = {
  notFound: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    marginTop: -10
  },
}


class SubscriptionsListPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadSubscriptions();
  }

  render() {
    if (!this.props.loaded && !this.props.loading) {
      return null;
    }

    return (
      <Layout
        title={'Подписки'}
        content={this.props.loading && this.props.subscriptions.length === 0 ? <Loader /> : this.renderContent()}
      />
    );
  }

  renderContent() {
    if (this.props.subscriptions.length === 0) {
      return (
        <div style={styles.notFound}>
          Подписок не найдены
        </div>
      );
    }

    return (
      <div>
        <h2>Подписки</h2>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>
              ИД
            </TableCell>
            <TableCell>
              Дата
            </TableCell>
            <TableCell>
              E-mail
            </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.subscriptions.map((one, index) =>
              <TableRow key={one.id}>
                <TableCell>{one.id}</TableCell>
                <TableCell>{one.created}</TableCell>
                <TableCell>{one.email}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    subscriptions: getSubscriptionsList(state),
    loading: getSubscriptionsLoading(state),
    loaded: getSubscriptionsLoaded(state)
  }),
  (dispatch, ownProps) => ({
    loadSubscriptions() {
      dispatch(loadSubscriptions());
    },
  })
)(SubscriptionsListPage));
