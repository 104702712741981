import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Table, {TableHead, TableBody, TableRow} from 'material-ui/Table'
import {default as TableCell} from '../../components/OrdersTableCell';
import Button from 'material-ui/Button';
import {loadBaskets, createOrderByCart} from '../../../../modules/operator/baskets'
import dataPage from '../../../../hocs/dataPage'
import BasketsPageSidebar from './BasketsPageSidebar';

const {
  getBasketsList,
  getBasketsLoading,
  getBasketsLoaded
} = require('../../../../selectors').default;


const BasketsHeaderRow = () =>
  <TableRow>
    <TableCell>
      ИД
    </TableCell>
    <TableCell>
      Дата создания
    </TableCell>
    <TableCell>
      E-mail
    </TableCell>
    <TableCell>
      Телефон
    </TableCell>
    <TableCell>
      ФИО
    </TableCell>
    <TableCell>
      Товаров
    </TableCell>
    <TableCell />
  </TableRow>


const BasketsListRow = ({basket, classes, createOrderByCart}) =>
  <TableRow>
    <TableCell>
      {basket.int_id}
    </TableCell>
    <TableCell>
      {basket.created}
    </TableCell>
    <TableCell>
      {basket.email}
    </TableCell>
    <TableCell>
      {basket.phone}
    </TableCell>
    <TableCell>
      {basket.fio}
    </TableCell>
    <TableCell>
      {basket.basketsCnt}
    </TableCell>
    <TableCell>
      <Button onClick={createOrderByCart}>Создать заказ</Button>
    </TableCell>
  </TableRow>

const BasketsListRowContainer = withRouter(connect(
  state => ({}),
  (dispatch, ownProps) => ({
    createOrderByCart() {
      dispatch(createOrderByCart(ownProps.history, ownProps.basket.id))
    }
  })
)(BasketsListRow));


const BasketsTable = ({items, createOrderByCart}) =>
  <Table>
    <TableHead>
      <BasketsHeaderRow/>
    </TableHead>
    <TableBody>
      {items.map((item, index) =>
        <BasketsListRowContainer
          key={index}
          basket={item}
        />
      )}
    </TableBody>
  </Table>



export default withRouter(connect(
  state => ({
    items: getBasketsList(state),
    loading: getBasketsLoading(state),
    loaded: getBasketsLoaded(state)
  }),
  dispatch => ({
    loadData() {
      dispatch(loadBaskets());
    }
  })
)(dataPage(BasketsTable, BasketsPageSidebar)))
