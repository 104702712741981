import React from 'react';
import {TableRow} from 'material-ui/Table';
import {default as TableCell} from '../../../orders/components/OrdersTableCell';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';


const styles = {
  bannerPhoto: {
    maxWidth: 100
  },
  firstRowColumn: {
    width: 30
  },
  openButton: {
    width: 40,
    height: 40
  }
};

class BannersListRow extends React.PureComponent {
  handleOpen = () => {
    this.props.onOpen(this.props.banner);
  }

  handleRemove = () => {
    this.props.onRemove(this.props.banner.id);
  }

  render() {
    const banner = this.props.banner;

    return (
      <TableRow>
        <TableCell style={styles.firstRowColumn}>
          <Button color="accent" fab onClick={this.handleOpen} style={styles.openButton}>
            <Icon className="material-icons">details</Icon>
          </Button>
        </TableCell>
        <TableCell>
          {banner.id}
        </TableCell>
        <TableCell>
          {banner.image_url ?
            <img src={banner.image_url} style={styles.bannerPhoto} alt="Фото"/>
            :
            <a>Фото отсутствует</a>
          }
        </TableCell>
        <TableCell>
          {banner.name}
        </TableCell>
        <TableCell>
          {banner.type_name}
        </TableCell>
        <TableCell>
          {parseInt(banner.is_active, 10) === 1 ? 'Да' : 'Нет'}
        </TableCell>
        <TableCell>
          {banner.weight}
        </TableCell>
        <TableCell>
          {banner.date_show_from}
        </TableCell>
        <TableCell>
          {banner.date_show_to}
        </TableCell>
        <TableCell>
          <IconButton title="Просмотр">
            <a href={banner.site_url} target="_blank">
              <Icon color="primary" className="material-icons">pageview</Icon>
            </a>
          </IconButton>
          <IconButton title="Удалить" onClick={this.handleRemove}>
            <Icon color="accent" className="material-icons">delete_forever</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default BannersListRow;
