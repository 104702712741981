import React from 'react';
import lightBlue from 'material-ui/colors/lightBlue';
import {ListItem, ListItemText} from 'material-ui/List';
import {withStyles} from 'material-ui/styles';
import Checkbox from 'material-ui/Checkbox';


// 1. We define the styles.
const styleSheet = theme => ({
	root: {
		fontSize: 11
	}
});


const ListItemTextStyled = withStyles(styleSheet, {name: 'ListItemText'})(ListItemText);

class FilterItem extends React.PureComponent {

  styleListItem = {
    padding: '0 8px 0 0',
    cursor: 'pointer'
  };

  styleCheckbox = {
    width: 20,
		height: 20,
    marginLeft: -2
  };

  styleCnt = {
    color: lightBlue['A700'],
		fontSize: 11
  };

  onChange = () => {
    this.props.onChange(this.props.item.id);
  };

  render() {
    return (
      <ListItem style={this.styleListItem} onClick={this.onChange}>
        <Checkbox
          style={this.styleCheckbox}
          checked={this.props.checked}
        />
        <ListItemTextStyled primary={this.props.item.name} disableTypography/>
        <span style={this.styleCnt}>{this.props.item.cnt}</span>
      </ListItem>
    );
  }
}

export default FilterItem;
