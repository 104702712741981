import React from 'react';
import {connect} from 'react-redux';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import {Row, Cell, Block} from '../../../../components/SubLayout';
import BasketsList from '../BasketsList';
import ProductsZone from '../ProductsZone';
import {openProductsZone} from '../../../../modules/orders';


const titleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const AddButton = props => <Button fab color="primary" {...props}><Icon className="material-icons">add</Icon></Button>;

class Baskets extends React.PureComponent {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <Block title={<div style={titleStyle}>Товары <AddButton onClick={this.handleOpen}/></div>} size={12}>
        <Row>
          <Cell size={12}>
            <BasketsList baskets={this.props.baskets} />
            <ProductsZone open={this.state.open} onRequestClose={this.handleClose} />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    openProductsZone() {
      dispatch(openProductsZone());
    }
  })
)(Baskets);
