import React, { Component } from 'react';


const mainRowStyle = {
  display: 'flex',
  flex: 1
};

const sidebarStyle = {
  paddingTop: 15,
  paddingLeft: 5,
  width: 280,
  flex: 'none',
  marginRight: 12,
  overflowY: 'auto',
  overflowX: 'hidden'
};

const contentStyle = {
  position: 'relative',
  flex: 1,
  overflowY: 'auto'
};

class Layout extends Component {
  render() {
    return (
      <div style={mainRowStyle}>
        {this.props.sidebar &&
          <div style={sidebarStyle}>
            {this.props.sidebar}
          </div>
        }
        {this.props.content &&
          <div style={contentStyle}>
            {this.props.content}
          </div>
        }
      </div>
    );
  }
}

export default Layout;
