import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../components/Layout';
import Button from 'material-ui/Button';
import Loader from '../../../components/Loader';
import { Container } from '../../../components/SubLayout';
import prefixer from '../../../prefixer';

import GeneralInfo from '../components/blocks/GeneralInfo';
import AdditionalInfo from '../components/blocks/AdditionalInfo';
import UserIdentity from '../components/blocks/UserIdentity';
import UserAdditionalInfo from '../components/blocks/UserAdditionalInfo';
import Baskets from '../components/blocks/Baskets';
import Calculation from '../components/blocks/Calculation';
import DeliveryAddress from '../components/blocks/DeliveryAddress';
import DeliveryParams from '../components/blocks/DeliveryParams';

import R from 'ramda';

import { loadOneOrder, setDraftOrderField, saveOrder, loadOrderByCart } from '../../../modules/orders';
const { getOrderDraftLoading, getOrderDraftOrder, getOrderDraftError } = require('../../../selectors').default;


const titleStyle = prefixer.prefix({
  padding: '10px 5px',
  margin: '0',
  background: '#fafafa'
});

const rootStyle = prefixer.prefix({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  position: 'absolute' // hack for Chrome 49
});

const contentStyle = prefixer.prefix({
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '0 10px'
});

const bottomStyle = prefixer.prefix({
  flex: 'none',
  padding: '7px 0',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: '#fafafa'
});

const buttonStyle = prefixer.prefix({
  marginLeft: 10
});

class OrderPage extends React.PureComponent {

  componentDidMount() {
    this.props.loadOneOrder();
  }

  setFieldValue = R.memoizeWith(path => JSON.stringify(path), path => event => {
    this.props.setDraftOrderField(path, event.target ? event.target.value : event);
  });

  onClose = () => {
    if (this.props.match.path.indexOf('new-by-cart') === -1) {
      this.props.history.push('/operator/orders');
    } else {
      this.props.history.push('/operator/baskets');
    }
  };

  isSaveEnabled = () => {
    return true; // R.path(['USER_DATA', 'PERSONAL_MOBILE'], this.props.order) || R.path(['USER_DATA', 'EMAIL'], this.props.order);
  };

  saveWarning = () => {
    return 'Для пользователя должен быть указан почтовый ящик или номер телефона';
  };

  render() {
    return (
      <Layout content={
        this.props.loading ?
          <Loader />
          :
          <div style={rootStyle}>
            <h3 style={titleStyle}>
              {this.props.order.ID || ''} {parseInt(this.props.order.TYPE_ID, 10) === 2 ? 'Возврат' : 'Заказ'}
            </h3>
            <div style={contentStyle}>
              {this.renderOrder()}
            </div>
            <div style={bottomStyle}>
              {!this.isSaveEnabled() && this.saveWarning()}
              {this.props.error}
              <Button raised color="accent" style={buttonStyle} onClick={this.onClose}>Закрыть</Button>
              <Button raised color="primary" style={buttonStyle} onClick={this.props.onSave} disabled={!this.isSaveEnabled()}>Сохранить</Button>
            </div>
          </div>
      } />
    );
  }

  renderOrder() {
    const order = this.props.order;
    const orderProps = order.props || {};

    return (
      <Container>
        <GeneralInfo order={order} />
        <AdditionalInfo order={order} setFieldValue={this.setFieldValue} ADMIN_COMMENT_FOR_USER={orderProps.ADMIN_COMMENT_FOR_USER} />
        <UserIdentity order={order} />
        <UserAdditionalInfo order={order} setFieldValue={this.setFieldValue} />
        <Baskets baskets={order.baskets} />
        <Calculation
          ID={order.ID}
          PRICE_FORMATTED={order.PRICE_FORMATTED}
          DISCOUNT_VALUE_FORMATTED={order.DISCOUNT_VALUE_FORMATTED}
          RESULT_PRICE_FORMATTED={order.RESULT_PRICE_FORMATTED}
          DELIVERY_PRICE_FORMATTED={order.DELIVERY_PRICE_FORMATTED}
          FULL_PRICE_FORMATTED={order.FULL_PRICE_FORMATTED}
        />
        <DeliveryAddress
          COUNTRY_ID={order.COUNTRY_ID}
          CITY_ID={order.CITY_ID}
          ZIP={orderProps.ZIP}
          METRO_STATION={orderProps.METRO_STATION}
          STREET={orderProps.STREET}
          MAILBOX={orderProps.MAILBOX}
          BUILDING={orderProps.BUILDING}
          ENTRANCE={orderProps.ENTRANCE}
          FLOOR={orderProps.FLOOR}
          NOTES={orderProps.NOTES}
          setFieldValue={this.setFieldValue}
          order={order}
        />
        <DeliveryParams order={order} setFieldValue={this.setFieldValue} />
      </Container>
    );
  }
}


export default connect(
  state => ({
    loading: getOrderDraftLoading(state),
    order: getOrderDraftOrder(state),
    error: getOrderDraftError(state)
  }),
  (dispatch, ownProps) => ({
    loadOneOrder() {
      if (ownProps.match.path.indexOf('new-by-cart') !== -1) {
        const id = ownProps.match.params.id;
        dispatch(loadOrderByCart(id));
      } else {
        const id = parseInt(ownProps.match.params.id || 0, 10);
        const typeId = parseInt(ownProps.match.params.typeId || 1, 10);
        dispatch(loadOneOrder(id, typeId));
      }
    },
    setDraftOrderField(field, value) {
      dispatch(setDraftOrderField(field, value));
    },
    onSave() {
      dispatch(saveOrder(ownProps.history, ownProps.match.params.id));
    }
  })
)(OrderPage);
