import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Table, {TableHead, TableBody} from 'material-ui/Table'
import ReviewsHeaderRow from '../components/ReviewsHeaderRow'
import ReviewsListRow from '../components/ReviewsListRow'
import {loadReviews} from '../../../../modules/operator/reviews'
import dataPage from '../../../../hocs/dataPage'

const {
  getReviewsList,
  getReviewsLoading,
  getReviewsLoaded
} = require('../../../../selectors').default;


const ReviewsTable = ({items}) =>
  <Table>
    <TableHead>
      <ReviewsHeaderRow/>
    </TableHead>
    <TableBody>
      {items.map((review, index) =>
        <ReviewsListRow
          key={index}
          review={review}
        />
      )}
    </TableBody>
  </Table>


export default withRouter(connect(
  state => ({
    items: getReviewsList(state),
    loading: getReviewsLoading(state),
    loaded: getReviewsLoaded(state)
  }),
  dispatch => ({
    loadData() {
      dispatch(loadReviews());
    }
  })
)(dataPage(ReviewsTable)))

