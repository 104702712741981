import React from 'react';
import Layout from '../../../../components/Layout';
import {connect} from 'react-redux';
import Table, {TableHead, TableBody} from 'material-ui/Table';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import {withRouter} from 'react-router';
import BannersListHeaderRow from '../components/BannersListHeaderRow';
import BannersListRow from '../components/BannersListRow';
import BannersListSidebar from './BannersListSidebar';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import SmartFab from '../../../../components/SmartFab';
import {loadBannersData, setPage, removeBanner} from '../../../../modules/marketer/banners/index';

const {
  getBannersList,
  getBannersTotal,
  getBannersPage,
  getBannersPageSize,
  getBannersLoading,
  getBannersLoaded
} = require('../../../../selectors').default;


const styles = {
  top: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between'
  },
  paginationContainer: {
    flex: '0 1 auto'
  },
  buttonsContainer: {
    flex: '0 1 auto'
  },
  bannerButton: {
    marginBottom: 5,
    marginLeft: 10
  },
  notFound: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    marginTop: -10
  },
  refresherContainer: {
    flex: 'none',
    margin: '0 20px'
  }
};

class BannersListPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadBannersData();
  }

  render() {
    if (!this.props.loaded && !this.props.loading) {
      return null;
    }

    return (
      <Layout
        content={this.props.loading && this.props.banners.length === 0 ? <Loader /> : this.renderContent()}
        sidebar={this.props.loaded && <BannersListSidebar/>}
      />
    );
  }

  renderContent() {
    if (this.props.banners.length === 0) {
      return (
        <div style={styles.notFound}>
          Баннеры не найдены
        </div>
      );
    }

    return (
      <div>
        <div style={styles.top}>
          {this.renderPagination()}
          {this.renderRefresher()}
          {this.renderButton()}
        </div>
        {this.renderTable()}
      </div>
    );
  }

  renderPagination() {
    return (
      <div style={styles.paginationContainer}>
        <Pagination
          total={this.props.total}
          current={this.props.page}
          pageSize={this.props.pageSize}
          onChange={this.props.handlePageChange}
        />
      </div>
    );
  }

  renderRefresher() {
    return (
      <div style={styles.refresherContainer}>
        <SmartFab processing={this.props.loading} onClick={this.props.loadBannersData} icon={'refresh'}/>
      </div>
    );
  }

  renderButton() {
    return (
      <div style={styles.buttonsContainer}>
        <Button
          raised
          color="primary"
          onClick={this.props.handleBannerCreate}
          style={styles.bannerButton}
        >
          Баннер <Icon className="material-icons">note_add</Icon>
        </Button>
      </div>
    );
  }

  renderTable() {
    return (
      <Table>
        <TableHead>
          <BannersListHeaderRow/>
        </TableHead>
        <TableBody>
          {this.props.banners.map((banner, index) =>
            <BannersListRow
              key={index}
              banner={banner}
              onOpen={this.props.handleBannerOpen}
              onRemove={this.props.handleBannerRemove}
            />
          )}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(connect(
  state => ({
    banners: getBannersList(state),
    total: getBannersTotal(state),
    page: getBannersPage(state),
    pageSize: getBannersPageSize(state),
    loading: getBannersLoading(state),
    loaded: getBannersLoaded(state)
  }),
  (dispatch, ownProps) => ({
    loadBannersData() {
      dispatch(loadBannersData());
    },
    handlePageChange(number) {
      dispatch(setPage(number));
      dispatch(loadBannersData());
    },
    handleBannerOpen(banner) {
      ownProps.history.push(`/marketer/banners/${banner.id}`);
    },
    handleBannerRemove(id) {
      if (window.confirm(`Вы уверены, что хотите УДАЛИТЬ баннер ИД ${id}?`)) {
        dispatch(removeBanner(id));
      }
    },
    handleBannerCreate() {
      ownProps.history.push('/marketer/banners/new');
    }
  })
)(BannersListPage));
