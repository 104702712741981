import React from 'react';
import { withStyles } from 'material-ui/styles';
import TextField from 'material-ui/TextField';
import { Container, Block, Row, Cell } from '../../../../components/SubLayout';
import CustomSelect from '../../../../components/CustomSelect';
import FormControl from 'material-ui/Form/FormControl';
import Input from 'material-ui/Input';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import R from 'ramda';


const styles = {
  img: {
    maxWidth: 300,
    maxHeight: 300
  }
};

const bannerActivityOptions = [
  { id: '0', text: 'Нет' },
  { id: '1', text: 'Да' }
];

class BannerwcLayout extends React.PureComponent {
  getTypeOptions() {
    if (!this.props.types) {
      return [];
    }
    return R.memoize(options => options.map(
      ({ id, name }) =>
        ({ id, text: name })
    ))(this.props.types);
  }

  handleUploadImage = type => event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('id', this.props.bannerwc.id);
    data.append('type', type);
    this.props.uploadImage(data);
  }

  render() {
    return (
      <Container>
        {this.renderBlockInfo()}
        {this.props.bannerwc.id &&
          this.renderBlockImages()
        }
      </Container>
    );
  }

  renderBlockInfo() {
    const bannerwc = this.props.bannerwc;

    return (
      <Block title="Информация о баннере">
        <Row>
          <Cell>ИД</Cell>
          <Cell>
            <strong>{bannerwc.id || ''}</strong>
          </Cell>
        </Row>
        <Row>
          <Cell>Адрес</Cell>
          <Cell>
            <TextField value={bannerwc.url || ''} onChange={this.props.setFieldValue(['url'])} />
          </Cell>
        </Row>
        <Row>
          <Cell>Активен</Cell>
          <Cell>
            <CustomSelect
              options={bannerActivityOptions}
              value={bannerwc.is_active}
              onChange={this.props.setFieldValue(['is_active'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Порядок</Cell>
          <Cell>
            <TextField value={bannerwc.weight || ''} onChange={this.props.setFieldValue(['weight'])} />
          </Cell>
        </Row>
        <Row>
          <Cell>Название</Cell>
          <Cell>
            <TextField value={bannerwc.name || ''} onChange={this.props.setFieldValue(['name'])} />
          </Cell>
        </Row>
        <Row>
          <Cell>Тип</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions()}
              value={bannerwc.type_id}
              onChange={this.props.setFieldValue(['type_id'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Уровень</Cell>
          <Cell>
            <TextField value={bannerwc.steplevel || ''} onChange={this.props.setFieldValue(['steplevel'])} />
          </Cell>
        </Row>
        <Row>
          <Cell>Интервал показа</Cell>
          <Cell>
            <TextField label="От" value={bannerwc.date_show_from || ''} onChange={this.props.setFieldValue(['date_show_from'])} />
            <TextField label="До" value={bannerwc.date_show_to || ''} onChange={this.props.setFieldValue(['date_show_to'])} />
          </Cell>
        </Row>
      </Block>
    );
  }

  renderBlockImages() {
    const bannerwc = this.props.bannerwc;

    return (
      <Block title="Изображения">
        <Row>
          <Cell>
            Горизонтальное
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl onChange={this.handleUploadImage('horizontal')}>
                <Input type="file" accept="image/jpeg, image/png" />
              </FormControl>

              {!!bannerwc.image_url &&
                <IconButton title="Удалить" onClick={this.props.removeBannerHorizontalImage}>
                  <Icon className="material-icons">delete_forever</Icon>
                </IconButton>
              }
            </div>
          </Cell>
          <Cell>
            {!!bannerwc.image_url &&
              <img
                alt="Баннер горизонтальный"
                className={this.props.classes.img}
                src={bannerwc.image_url || ''}
              />
            }
          </Cell>
        </Row>
        <Row>
          <Cell>
            Вертикальное
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl onChange={this.handleUploadImage('vertical')}>
                <Input type="file" accept="image/jpeg, image/png" />
              </FormControl>

              {!!bannerwc.image_vertical_url &&
                <IconButton title="Удалить" onClick={this.props.removeBannerVerticalImage}>
                  <Icon className="material-icons">delete_forever</Icon>
                </IconButton>
              }
            </div>
          </Cell>
          <Cell>
            {!!bannerwc.image_vertical_url &&
              <img
                alt="Баннер вертикальный"
                className={this.props.classes.img}
                src={bannerwc.image_vertical_url || ''}
              />
            }
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default withStyles(styles)(BannerwcLayout);
