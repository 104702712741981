import React from 'react';
import { CircularProgress } from 'material-ui/Progress';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import green from 'material-ui/colors/green';


const buttonStyle = {
  width: 36,
  height: 36
};

const buttonStyleProcessed = {
  ...buttonStyle,
  color: 'white',
  backgroundColor: green[500]
};


const progressStyle = {
  width: '100%'
};

const iconStyle = {
  position: 'absolute',
  top: 6,
  left: 6
};

class SmartFab extends React.PureComponent {

  render() {
    const {onClick, processing, processed, icon} = this.props;
    return (
      <Button fab style={processed === true ? buttonStyleProcessed : buttonStyle} onClick={onClick}>
        <Icon className="material-icons" style={iconStyle}>{processed === true ? 'check_circle' : icon}</Icon>
        {processing &&
          <CircularProgress style={progressStyle}/>
        }
      </Button>
    );
  }
}

export default SmartFab;
