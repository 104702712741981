export const LOAD_ONE_ORDER = 'orders/LOAD_ONE_ORDER';
export const LOAD_ORDER_BY_CART = 'orders/LOAD_ORDER_BY_CART';
export const LOAD_ONE_ORDER_COMPLETED = 'orders/LOAD_ONE_ORDER_COMPLETED';
export const LOAD_ONE_ORDER_COMPLETED_FULLY = 'orders/LOAD_ONE_ORDER_COMPLETED_FULLY';
export const LOAD_ORDERS = 'orders/LOAD';
export const LOAD_ORDERS_COMPLETED = 'orders/LOAD_COMPLETED';
export const SET_SOURCE_STOCK = 'orders/SET_SOURCE_STOCK';
export const TOGGLE_SOURCE = 'orders/TOGGLE_SOURCE';
export const TOGGLE_TYPE = 'orders/TOGGLE_TYPE';
export const TOGGLE_AGGREGATED = 'orders/TOGGLE_AGGREGATED';
export const TOGGLE_STATUS = 'orders/TOGGLE_STATUS';
export const SET_PAGE = 'orders/SET_PAGE';
export const CREATE_ORDER_BY_ID = 'orders/CREATE_BY_ID';
export const SET_SEARCH = 'orders/SET_SEARCH';
export const SET_SEARCH_ARTICLE = 'orders/SET_SEARCH_ARTICLE';
export const SET_SEARCH_SIZE = 'orders/SET_SEARCH_SIZE';
export const SET_DATE_DELIVERY_FROM = 'orders/SET_DATE_DELIVERY_FROM';
export const SET_DATE_DELIVERY_TO = 'orders/SET_DATE_DELIVERY_TO';
export const SET_DATE_CREATE_FROM = 'orders/SET_DATE_CREATE_FROM';
export const SET_DATE_CREATE_TO = 'orders/SET_DATE_CREATE_TO';

export const LOAD_BASKET_STATUSES_COMPLETED = 'orders/LOAD_BASKET_STATUSES_COMPLETED';
export const LOAD_DELIVERIES_COMPLETED = 'orders/LOAD_DELIVERIES_COMPLETED';
export const LOAD_PAY_SYSTEMS_COMPLETED = 'orders/LOAD_PAY_SYSTEMS_COMPLETED';
export const LOAD_METRO_STATIONS_COMPLETED = 'orders/LOAD_METRO_STATIONS_COMPLETED';
export const LOAD_CITIES_COMPLETED = 'orders/LOAD_CITIES_COMPLETED';
export const LOAD_COUNTRIES_COMPLETED = 'orders/LOAD_COUNTRIES_COMPLETED';
export const LOAD_BASKET_SOLDES_COMPLETED = 'orders/LOAD_BASKET_SOLDES_COMPLETED';

export const SEND_CONFIRMATION_EMAIL = 'orders/SEND_CONFIRMATION_EMAIL';
export const SEND_CONFIRMATION_EMAIL_COMPLETED = 'orders/SEND_CONFIRMATION_EMAIL_COMPLETED';
export const SEND_CONFIRMATION_EMAIL_COMPLETED_FULLY = 'orders/SEND_CONFIRMATION_EMAIL_COMPLETED_FULLY';

export const SEND_CONFIRMATION_SMS = 'orders/SEND_CONFIRMATION_SMS';
export const SEND_CONFIRMATION_SMS_COMPLETED = 'orders/SEND_CONFIRMATION_SMS_COMPLETED';
export const SEND_CONFIRMATION_SMS_COMPLETED_FULLY = 'orders/SEND_CONFIRMATION_SMS_COMPLETED_FULLY';

export const SEND_ECARD_SMS = 'orders/SEND_ECARD_SMS';
export const SEND_ECARD_SMS_COMPLETED = 'orders/SEND_ECARD_SMS_COMPLETED';
export const SEND_ECARD_SMS_COMPLETED_FULLY = 'orders/SEND_ECARD_SMS_COMPLETED_FULLY';

export const SEND_DELIV_SMS = 'orders/SEND_DELIV_SMS';
export const SEND_DELIV_SMS_COMPLETED = 'orders/SEND_DELIV_SMS_COMPLETED';
export const SEND_DELIV_SMS_COMPLETED_FULLY = 'orders/SEND_DELIV_SMS_COMPLETED_FULLY';

export const SEND_PAYMENT_URL = 'orders/SEND_PAYMENT_URL';
export const VIEW_PAYMENT_URL = 'orders/VIEW_PAYMENT_URL';
export const SEND_PAYMENT_URL_COMPLETED = 'orders/SEND_PAYMENT_URL_COMPLETED';
export const SEND_PAYMENT_URL_COMPLETED_FULLY = 'orders/SEND_PAYMENT_URL_COMPLETED_FULLY';

export const SEND_PAYMENT_SMS = 'orders/SEND_PAYMENT_SMS';
export const SEND_PAYMENT_SMS_COMPLETED = 'orders/SEND_PAYMENT_SMS_COMPLETED';
export const SEND_PAYMENT_SMS_COMPLETED_FULLY = 'orders/SEND_PAYMENT_SMS_COMPLETED_FULLY';

export const ORDERS_EXPORT = 'orders/ORDERS_EXPORT';
export const ORDERS_EXPORT_COMPLETED = 'orders/ORDERS_EXPORT_COMPLETED';
export const ORDERS_EXPORT_COMPLETED_FULLY = 'orders/ORDERS_EXPORT_COMPLETED_FULLY';
export const ORDERS_EXPORT_SET_LINK = 'orders/ORDERS_EXPORT_SET_LINK';
export const ORDERS_EXPORT_UNSET_LINK = 'orders/ORDERS_EXPORT_UNSET_LINK';

export const SET_DRAFT_ORDER_FIELD = 'orders/SET_DRAFT_ORDER_FIELD';
export const SET_DRAFT_PRODUCTS_SEARCH = 'orders/SET_DRAFT_PRODUCTS_SEARCH';

export const SEARCH_PRODUCTS = 'orders/SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_COMPLETED = 'orders/SEARCH_PRODUCTS_COMPLETED';

export const ADD_TO_DRAFT_BASKET = 'orders/ADD_TO_DRAFT_BASKET';
export const ADD_NEW_BASKETS_TO_DRAFT = 'orders/ADD_NEW_BASKETS_TO_DRAFT';

export const SAVE_ORDER = 'orders/SAVE_ORDER';
export const SAVE_ORDER_COMPLETED = 'orders/SAVE_ORDER_COMPLETED';
export const SAVE_ORDER_ERROR = 'orders/SAVE_ORDER_ERROR';

export const LOAD_USER_COMPLETED = 'orders/LOAD_USER_COMPLETED';

export const SET_BASKET_STATUS_ID = 'orders/SET_BASKET_STATUS_ID';
export const SET_BASKET_QTY = 'orders/SET_BASKET_QTY';
export const SET_ADMIN_COMMENT = 'orders/SET_ADMIN_COMMENT';
export const SET_BASKET_GOODS_MANAGER_COMMENT = 'orders/SET_BASKET_GOODS_MANAGER_COMMENT';
export const REMOVE_BASKET = 'orders/REMOVE_BASKET';
export const SET_BASKET_SOLD = 'orders/SET_BASKET_SOLD';
export const SET_BASKET_PROMOKOD = 'orders/SET_BASKET_PROMOKOD';

export const OPEN_PRODUCTS_ZONE = 'orders/OPEN_PRODUCTS_ZONE';

export const LOAD_ORDER_STATUSES_COMPLETED = 'orders/LOAD_ORDER_STATUSES_COMPLETED';

export const LOAD_LAST_ORDER_ID = 'orders/LOAD_LAST_ORDER_ID';
export const CHECK_NEW_ORDER = 'orders/CHECK_NEW_ORDER';
export const LOAD_LAST_ORDER_ID_COMPLETED = 'orders/LOAD_LAST_ORDER_ID_COMPLETED';

export const CALC_PRICES = 'orders/CALC_PRICES';
export const CALC_PRICES_COMPLETED = 'orders/CALC_PRICES_COMPLETED';
export const CALC_PRICES_COMPLETED_FULLY = 'orders/CALC_PRICES_COMPLETED_FULLY';