import { put, all, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import R from 'ramda';
const types = require('./types');
const {
  getBannersPageWc,
  getBannersPageSizeWc,
  getBannerWc,
  getBannersActivityTypesSelectedWc,
  getBannersTypesSelectedWc
} = require('../../../selectors').default;


function* loadBannersDataSaga() {
  const state = yield select();

  const page = getBannersPageWc(state);
  const pageSize = getBannersPageSizeWc(state);
  const offset = pageSize * (page - 1);

  const activityTypes = getBannersActivityTypesSelectedWc(state);

  const result = yield axios({
    method: 'GET',
    url: '/api/marketer/bannerWC/list',
    params: R.pickBy((val, key) => !!val, {
      offset,
      is_active: activityTypes.length === 1 ? activityTypes[0] : [],
      type_id: getBannersTypesSelectedWc(state)
    }),
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    withCredentials: true
  });

  yield put({
    type: types.LOAD_BANNERS_DATA_COMPLETED,
    bannerswc: result.data.banners,
    pageSize: result.data.pageSize,
    total: result.data.total
  });
}

function* removeBannerSaga({ id }) {
  yield axios({
    method: 'POST',
    url: '/api/marketer/bannerWC/delete',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({ type: types.LOAD_BANNERS_DATA_WC });
}

function* loadOneBannerSaga({ id }) {
  let bannerwc;
  if (id === 0) {
    bannerwc = {};
  } else {
    const result = yield axios({
      method: 'GET',
      url: '/api/marketer/bannerWC/view',
      params: {
        id
      },
      withCredentials: true
    });
    bannerwc = result.data;
  }

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    bannerwc
  });
}

function* loadBannerTypesSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/marketer/bannerWC/types',
    withCredentials: true
  });

  yield put({
    type: types.LOAD_BANNER_TYPES_COMPLETED,
    bannerwcTypes: result.data
  });
}

function* uploadBannerImageSaga({ data }) {
  const result = yield axios({
    method: 'POST',
    url: '/api/marketer/bannerWC/upload',
    data
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    bannerwc: result.data
  });
}

function* removeBannerImageSaga({ id, type }) {
  const result = yield axios({
    method: 'POST',
    url: `/api/marketer/bannerWC/${type === 'horizontal' ? 'removeHorizontalImage' : 'removeVerticalImage'}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({
    type: types.LOAD_ONE_BANNER_COMPLETED,
    bannerwc: result.data
  });
}

function* removeBannerHorizontalImageSaga({ id }) {
  yield removeBannerImageSaga({ id, type: 'horizontal' })
}

function* removeBannerVerticalImageSaga({ id }) {
  yield removeBannerImageSaga({ id, type: 'vertical' })
}

function* saveBannerSaga({ history }) {
  const state = yield select();
  const bannerwc = getBannerWc(state);

  const result = yield axios({
    method: 'POST',
    url: '/api/marketer/bannerWC/save',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      ...bannerwc
    })
  });

  if (bannerwc.id) {
    yield put({
      type: types.LOAD_ONE_BANNER_COMPLETED,
      bannerwc: result.data
    });
  } else {
    yield put({
      type: types.LOAD_ONE_BANNER,
      id: result.data.id
    });
    history.push(`/marketer/bannerswc/${result.data.id}`);
  }
}

export default function* () {
  yield all([
    takeLatest(types.LOAD_BANNERS_DATA_WC, loadBannersDataSaga),
    takeLatest(types.REMOVE_BANNER, removeBannerSaga),
    takeLatest(types.LOAD_ONE_BANNER, loadOneBannerSaga),
    takeLatest(types.LOAD_BANNER_TYPES, loadBannerTypesSaga),
    takeLatest(types.UPLOAD_BANNER_IMAGE, uploadBannerImageSaga),
    takeLatest(types.REMOVE_BANNER_HORIZONTAL_IMAGE, removeBannerHorizontalImageSaga),
    takeLatest(types.REMOVE_BANNER_VERTICAL_IMAGE, removeBannerVerticalImageSaga),
    takeLatest(types.SAVE_BANNER, saveBannerSaga)
  ]);
}
