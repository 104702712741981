export const LOAD_BANNERS_DATA = 'banners/LOAD_BANNERS_DATA';
export const LOAD_BANNERS_DATA_COMPLETED = 'banners/LOAD_BANNERS_DATA_COMPLETED';

export const LOAD_ONE_BANNER = 'banners/LOAD_ONE_BANNER';
export const LOAD_ONE_BANNER_COMPLETED = 'banners/LOAD_ONE_BANNER_COMPLETED';

export const REMOVE_BANNER = 'banners/REMOVE_BANNER';

export const SET_PAGE = 'banners/SET_PAGE';

export const LOAD_BANNER_TYPES = 'banners/LOAD_BANNER_TYPES';
export const LOAD_BANNER_TYPES_COMPLETED = 'banners/LOAD_BANNER_TYPES_COMPLETED';

export const UPLOAD_BANNER_IMAGE = 'banners/UPLOAD_BANNER_IMAGE';

export const SET_DRAFT_BANNER_FIELD = 'banners/SET_DRAFT_BANNER_FIELD';

export const SAVE_BANNER = 'banners/SAVE_BANNER';

export const TOGGLE_ACTIVITY_TYPE = 'banners/TOGGLE_ACTIVITY_TYPE';
export const TOGGLE_TYPE = 'banners/TOGGLE_TYPE';

export const REMOVE_BANNER_HORIZONTAL_IMAGE = 'banners/REMOVE_BANNER_HORIZONTAL_IMAGE';
export const REMOVE_BANNER_VERTICAL_IMAGE = 'banners/REMOVE_BANNER_VERTICAL_IMAGE';
