import React from 'react';
import {connect} from 'react-redux';
import {Row, Cell, Block} from '../../../../components/SubLayout';
import {calcPrices} from '../../../../modules/orders/actions';
import SmartFab from '../../../../components/SmartFab';
const {getOrderDraftPricesProcessing} = require('../../../../selectors').default;


class Calculation extends React.PureComponent {
  render() {
    return (
      <Block size={12}>
        <Row>
          <Cell size={3}>Сумма заказа</Cell>
          <Cell size={3}>{this.props.PRICE_FORMATTED} руб.</Cell>
          <Cell size={3}>Сумма скидки</Cell>
          <Cell size={3}>{this.props.DISCOUNT_VALUE_FORMATTED} руб.</Cell>
        </Row>
        <Row>
          <Cell size={3}>Сумма заказа со скидкой</Cell>
          <Cell size={3}>{this.props.RESULT_PRICE_FORMATTED} руб.</Cell>
          <Cell size={3}>Стоимость доставки</Cell>
          <Cell size={3}>{this.props.DELIVERY_PRICE_FORMATTED} руб.</Cell>
        </Row>
        <Row>
          <Cell size={3}><strong>Сумма заказа к оплате</strong></Cell>
          <Cell size={3}><strong>{this.props.FULL_PRICE_FORMATTED} руб.</strong></Cell>
          <Cell size={3}>
            <SmartFab
              onClick={this.props.calcPrices}
              processing={this.props.pricesProcessing.processing}
              processed={this.props.pricesProcessing.processed}
              icon={'autorenew'}
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default connect(
  state => ({
    pricesProcessing: getOrderDraftPricesProcessing(state)
  }),
  (dispatch, ownProps) => ({
    calcPrices() {
      dispatch(calcPrices(ownProps.ID));
    }
  })
)(Calculation);
