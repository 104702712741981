import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {
  setSourceStock,
  setSearch,
  setSearchArticle,
  setSearchSize,
  loadOrders,
  toggleSource,
  toggleType,
  toggleAggregated,
  toggleStatus,
  setDateDeliveryFrom,
  setDateDeliveryTo,
  setDateCreateFrom,
  setDateCreateTo
} from '../../../modules/orders/index';
import CustomSelect from '../../../components/CustomSelect';
import Filter from '../../../components/Filter';
import DateRangeFilter from '../components/DateRangeFilter';
import OrderDateFilter from '../components/OrderDateFilter';
import Input from 'material-ui/Input';
import FormControl from 'material-ui/Form/FormControl';
import {withStyles} from 'material-ui/styles';
const {
  getOrderSourceStocks,
  getOrderSourceStockId,
  getOrderSources,
  getOrderSourcesSelected,
  getOrderTypes,
  getOrderTypesSelected,
  getOrderAggregated,
  getOrderAggregatedSelected,
  getOrderStatuses,
  getOrderStatusesSelected,
  getOrdersSearch,
  getOrdersSearchArticle,
  getOrdersSearchSize,
  getOrdersDateDeliveryFrom,
  getOrdersDateDeliveryTo,
  getOrdersDateCreateFrom,
  getOrdersDateCreateTo
} = require('../../../selectors').default;


const styleSheet = theme => ({
  formControl: {
    fontSize: 13,
    width: '100%'
  },
  inputLabel: {
    transform: 'none !important'
  }
});

const inputStyleSheet = theme => ({
  root: {
    marginTop: '5px !important',
    width: '100%'
  }
});
const InputStyled = withStyles(inputStyleSheet, {name: 'Input'})(Input);


const CustomTextField = withStyles(styleSheet, {name: 'TextField'})(props => (
  <FormControl className={props.classes.formControl}>
    <InputStyled value={props.value} onChange={props.onChange} placeholder={props.label} />
  </FormControl>
));


const getAggregatedOptions = createSelector(
  aggregated => aggregated,
  aggregated => aggregated.map(one => ({id: one.value, name: one.name, cnt: one.cnt}))
);

class OrdersListSidebar extends React.PureComponent {

  onSearchChange = e => {
    e.preventDefault();
    this.props.setSearch(e.target.value);
  };

  onArticleChange = e => {
    e.preventDefault();
    this.props.setSearchArticle(e.target.value);
  };

  onSizeChange = e => {
    e.preventDefault();
    this.props.setSearchSize(e.target.value);
  };

  render() {
    return (
      <div>
        {this.renderSourceStockFilter()}
        {this.renderSearch()}
        {this.renderSearchArticle()}
        {this.renderSearchSize()}
        {this.renderDateDeliveryFilter()}
        {this.renderDateCreateFilter()}
        {this.renderSourceFilter()}
        {this.renderTypeFilter()}
        {this.renderAggregatedFilter()}
        {this.renderStatusFilter()}
      </div>
    );
  }

  renderSourceStockFilter() {
  	if (!this.props.sourceStocks.length) {
  		return null;
	  }
    return <CustomSelect
      label="Источник-склад"
      value={this.props.sourceStockId}
      onChange={this.props.setSourceStock}
      options={this.props.sourceStocks}
    />;
  }

  renderSearch() {
    return <CustomTextField
      label="Поиск"
      value={this.props.search}
      onChange={this.onSearchChange}
    />;
  }

  renderSearchArticle() {
    return <CustomTextField
      label="Артикул"
      value={this.props.article}
      onChange={this.onArticleChange}
    />;
  }

  renderSearchSize() {
    return <CustomTextField
      label="Размер"
      value={this.props.size}
      onChange={this.onSizeChange}
    />;
  }

  renderDateDeliveryFilter() {
    return <DateRangeFilter
      leftPicker={<OrderDateFilter value={this.props.deliveryFrom} onChange={this.props.setDateDeliveryFrom} label="Доставка от"/>}
      rightPicker={<OrderDateFilter value={this.props.deliveryTo} onChange={this.props.setDateDeliveryTo} label="Доставка до"/>}
    />;
  }

  renderDateCreateFilter() {
    return <DateRangeFilter
      leftPicker={<OrderDateFilter value={this.props.createFrom} onChange={this.props.setDateCreateFrom} label="Создание от"/>}
      rightPicker={<OrderDateFilter value={this.props.createTo} onChange={this.props.setDateCreateTo} label="Создание до"/>}
    />;
  }

  renderSourceFilter() {
    return <Filter
      label="Источник"
      items={this.props.sources}
      selected={this.props.sourcesSelected}
      onToggle={this.props.toggleSource}
    />;
  }

  renderTypeFilter() {
    return <Filter
      label="Тип"
      items={this.props.types}
      selected={this.props.typesSelected}
      onToggle={this.props.toggleType}
    />;
  }

  renderAggregatedFilter() {
    return <Filter
      label="Готовность"
      items={getAggregatedOptions(this.props.aggregated)}
      selected={this.props.aggregatedSelected}
      onToggle={this.props.toggleAggregated}
    />;
  }

  renderStatusFilter() {
    return <Filter
      label="Статус"
      items={this.props.statuses}
      selected={this.props.statusesSelected}
      onToggle={this.props.toggleStatus}
    />;
  }
}

export default connect(
  state => ({
    sourceStocks: getOrderSourceStocks(state),
    sourceStockId: getOrderSourceStockId(state),
    sources: getOrderSources(state),
    sourcesSelected: getOrderSourcesSelected(state),
    types: getOrderTypes(state),
    typesSelected: getOrderTypesSelected(state),
    aggregated: getOrderAggregated(state),
    aggregatedSelected: getOrderAggregatedSelected(state),
    statuses: getOrderStatuses(state),
    statusesSelected: getOrderStatusesSelected(state),
    search: getOrdersSearch(state),
    article: getOrdersSearchArticle(state),
    size: getOrdersSearchSize(state),
    deliveryFrom: getOrdersDateDeliveryFrom(state),
    deliveryTo: getOrdersDateDeliveryTo(state),
    createFrom: getOrdersDateCreateFrom(state),
    createTo: getOrdersDateCreateTo(state)
  }),
  dispatch => ({
    setSourceStock(id) {
      dispatch(setSourceStock(id));
      dispatch(loadOrders());
    },
    setSearch(search) {
      dispatch(setSearch(search));
      dispatch(loadOrders());
    },
    setSearchArticle(article) {
      dispatch(setSearchArticle(article));
      dispatch(loadOrders());
    },
    setSearchSize(size) {
      dispatch(setSearchSize(size));
      dispatch(loadOrders());
    },
    toggleSource(id) {
      dispatch(toggleSource(id));
      dispatch(loadOrders());
    },
    toggleType(id) {
      dispatch(toggleType(id));
      dispatch(loadOrders());
    },
    toggleAggregated(value) {
      dispatch(toggleAggregated(value));
      dispatch(loadOrders());
    },
    toggleStatus(id) {
      dispatch(toggleStatus(id));
      dispatch(loadOrders());
    },
    setDateDeliveryFrom(date) {
      dispatch(setDateDeliveryFrom(date));
      dispatch(loadOrders());
    },
    setDateDeliveryTo(date) {
      dispatch(setDateDeliveryTo(date));
      dispatch(loadOrders());
    },
    setDateCreateFrom(date) {
      dispatch(setDateCreateFrom(date));
      dispatch(loadOrders());
    },
    setDateCreateTo(date) {
      dispatch(setDateCreateTo(date));
      dispatch(loadOrders());
    }
  })
)(OrdersListSidebar);
