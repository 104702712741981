import React from 'react';
import { connect } from 'react-redux';
import { Block, Row, Cell } from '../../../../components/SubLayout';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import TextField from 'material-ui/TextField';
import SmartFab from '../../../../components/SmartFab';
import CustomSelect from '../../../../components/CustomSelect';
import { sendConfirmationEmail, sendConfirmationSms, sendEcardSms, sendDelivSms } from '../../../../modules/orders';
import R from 'ramda';
const { getOrderDraftConfirmationEmail, getOrderDraftConfirmationSms, getOrderStatuses, getOrderDraftecardSms, getOrderDraftdelivSms } = require('../../../../selectors').default;


const smallFabStyle = {
  width: 36,
  height: 36
};

const billLinkStyle = {
  lineHeight: 0,
  color: 'black'
};

const memoizedOptions = R.memoize(
  items => items.map(
    ({ id, name }) =>
      ({ id, text: name })
  )
);

class AdditionalInfo extends React.PureComponent {
  render() {
    const order = this.props.order;
    //const showmodules = order.showmodules ? order.showmodules : {};
    const deliveryparams = order.showmodules && order.showmodules.deliveryparams;
    const typename = order.TYPE_NAME;
    return (
      <Block title={<br />}>
        <Row>
          <Cell>Письмо о подтверждении</Cell>
          <Cell>
            <SmartFab
              onClick={this.props.sendConfirmationEmail}
              processing={this.props.confirmationEmail.processing}
              processed={this.props.confirmationEmail.processed}
              icon={'email'}
            />
          </Cell>
        </Row>
        {deliveryparams ? "" :
          (
            <Row>
              <Cell>Смс о подтверждении</Cell>
              <Cell>
                <SmartFab
                  onClick={this.props.sendConfirmationSms}
                  processing={this.props.confirmationSms.processing}
                  processed={this.props.confirmationSms.processed}
                  icon={'sms'}
                />
              </Cell>
            </Row>
          )}
        <Row>
          <Cell>Счет</Cell>
          <Cell>
            <Button fab style={smallFabStyle}>
              <a style={billLinkStyle} target="_blank" rel="noopener noreferrer" href={`/api/operator/order/bill?orderId=${order.ID}`}>
                <Icon className="material-icons">open_in_new</Icon>
              </a>
            </Button>
          </Cell>
        </Row>
        <Row>
          <Cell>Смс электронной карты</Cell>
          <Cell>
            <SmartFab
              onClick={this.props.sendEcardSms}
              processing={this.props.ecardSms.processing}
              processed={this.props.ecardSms.processed}
              icon={'credit_card'}
            />
          </Cell>
        </Row>
        {deliveryparams ? "" :
          (
            <Row>
              <Cell>Смс о доставке</Cell>
              <Cell>
                <SmartFab
                  onClick={this.props.sendDelivSms}
                  processing={this.props.delivSms.processing}
                  processed={this.props.delivSms.processed}
                  icon={'sms'}
                />
              </Cell>
            </Row>
          )}
        <Row>
          <Cell>Статус</Cell>
          <Cell>
            <CustomSelect
              label="Статус заказа"
              options={memoizedOptions(this.props.statuses)}
              value={order.STATUS_ID}
              onChange={this.props.setFieldValue(['STATUS_ID'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Тип</Cell>
          <Cell>
            {deliveryparams && order.types_sellers ? <CustomSelect
              label="Тип заказа"
              options={memoizedOptions(order.types_sellers)}
              value={order.TYPE_ID}
              onChange={this.props.setFieldValue(['TYPE_ID'])}
            /> : typename}
          </Cell>
        </Row>
        <Row>
          <Cell>Готовность</Cell>
          <Cell>{order.AGGREGATED_NAME}</Cell>
        </Row>
        <Row>
          <Cell>{deliveryparams ? 'ФИО продавца' : 'Комментарий (ВИДЕН клиенту)'}</Cell>
          <Cell>
            <TextField multiline value={this.props.ADMIN_COMMENT_FOR_USER || ''} onChange={this.props.setFieldValue(['props', 'ADMIN_COMMENT_FOR_USER'])} style={{ fontSize: 13, width: '100%' }} />
          </Cell>
        </Row>
        <Row>
          <Cell>Комментарий</Cell>
          <Cell>
            <TextField multiline value={order.COMMENTS || ''} onChange={this.props.setFieldValue(['COMMENTS'])} style={{ fontSize: 13, width: '100%' }} />
          </Cell>
        </Row>
        <Row>
          <Cell>
            Комментарий покупателя:
          </Cell>
          <Cell>
            {order.USER_DESCRIPTION}
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default connect(
  state => ({
    confirmationEmail: getOrderDraftConfirmationEmail(state),
    confirmationSms: getOrderDraftConfirmationSms(state),
    ecardSms: getOrderDraftecardSms(state),
    delivSms: getOrderDraftdelivSms(state),
    statuses: getOrderStatuses(state)
  }),
  (dispatch, ownProps) => ({
    sendConfirmationEmail() {
      dispatch(sendConfirmationEmail(ownProps.order.ID));
    },
    sendConfirmationSms() {
      dispatch(sendConfirmationSms(ownProps.order.ID));
    },
    sendEcardSms() {
      dispatch(sendEcardSms(ownProps.order.ID));
    },
    sendDelivSms() {
      dispatch(sendDelivSms(ownProps.order.ID));
    }
  })

)(AdditionalInfo);
