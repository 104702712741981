import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {all} from 'redux-saga/effects'
import {reducers, sagas} from './modules';
import createSagaMiddleware from 'redux-saga';


const initialState = Object
  .keys(reducers)
  .reduce(
    (result, key) =>
      ({...result, [key]: undefined}),
    {}
  )
;

const reducer = combineReducers(reducers);
const composeDev = process.env.NODE_ENV !== 'production' && (typeof window !== 'undefined') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeDev || compose;

const sagaMiddleware = createSagaMiddleware();

export default createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);


sagaMiddleware.run(function*() {
  yield all(sagas)
});
