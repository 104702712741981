import {put, all, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import R from 'ramda';
import qs from 'qs';


const LOAD_REVIEWS = 'reviews/LOAD_REVIEWS';
const LOAD_REVIEWS_COMPLETED = 'reviews/LOAD_REVIEWS_COMPLETED';
const REMOVE_REVIEW = 'reviews/REMOVE_REVIEW';


const initialState = {
  reviews: [],
  loading: false,
  loaded: false
}

export function reducer (state = initialState, action) {
  switch(action.type) {
    case LOAD_REVIEWS:
      return {
        ...state,
        loading: true
      };
    case LOAD_REVIEWS_COMPLETED:
      return {
        ...state,
        reviews: action.reviews,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
}

export const loadReviews = () => ({
  type: LOAD_REVIEWS
});


function* loadReviewsSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/operator/clientService/list',
  });

  yield put({
    type: LOAD_REVIEWS_COMPLETED,
    reviews: result.data.items
  });
}

export const removeReview = id => ({type: REMOVE_REVIEW, id})

function* removeReviewSaga({id}) {
  yield axios({
    method: 'POST',
    url: '/api/operator/clientService/delete',
    data: qs.stringify({id})
  })

  yield loadReviewsSaga()
}

export function* saga() {
  yield all([
    takeLatest(LOAD_REVIEWS, loadReviewsSaga),
    takeLatest(REMOVE_REVIEW, removeReviewSaga)
  ]);
}

export const selectors = {
  getReviewsList: R.path(['reviews']),
  getReviewsLoading: state => state.loading,
  getReviewsLoaded: state => state.loaded,
}
