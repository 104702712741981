import React from 'react';


class OrderDeliveryDate extends React.PureComponent {
  render() {
    let deliveryDate = this.props.order.DELIVERY_DATE;
    let range = this.props.order.DELIVERY_TIME_RANGE;

    return (
      <span>
        {deliveryDate ? deliveryDate : 'Нет даты'}
        {range && <br/>}
        {range && range}
      </span>
    );
  }
}

export default OrderDeliveryDate;
