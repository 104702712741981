import React from 'react';
import { TableRow } from 'material-ui/Table';
import { default as TableCell } from '../../../orders/components/OrdersTableCell';


class BannerswcListHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableRow>
        <TableCell>
        </TableCell>
        <TableCell>
          ИД
        </TableCell>
        <TableCell>
          Изображение
        </TableCell>
        <TableCell>
          Название
        </TableCell>
        <TableCell>
          Тип
        </TableCell>
        <TableCell>
          Активность
        </TableCell>
        <TableCell>
          Порядок
        </TableCell>
        <TableCell>
          Уровень
        </TableCell>
        <TableCell>
          Действует с
        </TableCell>
        <TableCell>
          Действует по
        </TableCell>
        <TableCell>
          Действие
        </TableCell>
      </TableRow>
    );
  }
}

export default BannerswcListHeaderRow;
