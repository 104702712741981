import React from 'react';
import {connect} from 'react-redux';
import R from 'ramda';
import Layout from '../../../components/Layout';
import {Container} from '../../../components/SubLayout';
import Button from 'material-ui/Button';
import Info from '../components/Info';
import Description from '../components/Description';
import Parameters from '../components/Parameters';
import Image from '../components/Image';
import ProductRemains from '../components/ProductRemains';
import ProductComments from '../components/ProductComments';
import {
  loadOneProductRemains,
  loadOneProduct,
  setDraftProductField,
  uploadProductImage,
  saveProduct,
  loadProductStyles,
  loadProductCollections,
  loadProductRootSections,
  loadProductBrands,
  loadProductCars,
  loadProductCountries
} from '../../../modules/products';

const {
  getProductRemains,
  getProduct,
  getProductStyles,
  getProductCollections,
  getProductRootSections,
  getProductCategories,
  getProductBrands,
  getProductCars,
  getProductCountries
} = require('../../../selectors').default;


const styles = {
  title: {
    padding: '0 5px',
    margin: 0
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 10px'
  },
  bottom: {
    flex: 'none',
    padding: '7px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginLeft: 10
  }
}

class ProductPage extends React.PureComponent {

  componentDidMount() {
    this.props.loadOneProductRemains();
    this.props.loadOneProduct();
  }

  setFieldValue = R.memoizeWith(path => JSON.stringify(path), path => event => {
    this.props.setDraftProductField(path, event.target ? event.target.value : event);
  });

  setFieldSelect = R.memoizeWith(parameters => JSON.stringify(parameters), parameters => event => {
    const value = parameters.options.filter(one => one.id === event)[0].name;
    this.props.setDraftProductField(parameters.path, value);
  });

  handleClose = () => {
    this.props.history.push('/catalog/products');
  };

  render() {
    const product = this.props.product;

    return (
      <Layout content={
        <div style={styles.root}>
          <h3 style={styles.title}>
            Продукт № {product.ID}
          </h3>
          <div style={styles.content}>
            {this.renderProduct()}
          </div>
          <div style={styles.bottom}>
            <Button raised color="accent" style={styles.button} onClick={this.handleClose}>Закрыть</Button>
            <Button raised color="primary" style={styles.button} onClick={this.props.handleSave}>Сохранить</Button>
          </div>
        </div>
      }/>
    );
  }

  renderProduct() {
    return (
      <div>
        <Container>
          <ProductRemains productId={this.props.product.ID} remains={this.props.productRemains} product={this.props.product} loadOneProductRemains={this.props.loadOneProductRemains} />
        </Container>
        <Container>
          <ProductComments productId={this.props.product.ID} remains={this.props.productRemains} product={this.props.product} loadOneProductRemains={this.props.loadOneProductRemains} />
        </Container>
        <Container>
          <Info product={this.props.product} setFieldValue={this.setFieldValue}/>
          <Description product={this.props.product} setFieldValue={this.setFieldValue}/>
          <Parameters
            product={this.props.product}
            setFieldValue={this.setFieldValue}
            setFieldSelect={this.setFieldSelect}
            styles={this.props.styles}
            collections={this.props.collections}
            rootSections={this.props.rootSections}
            categories={this.props.categories}
            brands={this.props.brands}
            cars={this.props.cars}
            countries={this.props.countries}
          />
          <Image product={this.props.product} uploadImage={this.props.uploadProductImage}/>
        </Container>
      </div>
    );
  }
}

export default connect(
  state => ({
    styles: getProductStyles(state),
    collections: getProductCollections(state),
    rootSections: getProductRootSections(state),
    categories: getProductCategories(state),
    brands: getProductBrands(state),
    cars: getProductCars(state),
    countries: getProductCountries(state),
    productRemains: getProductRemains(state),
    product: getProduct(state)
  }),
  (dispatch, ownProps) => ({
    loadOneProductRemains() {
      const id = parseInt(ownProps.match.params.id || 0, 10);
      dispatch(loadOneProductRemains(id));
    },
    loadOneProduct() {
      dispatch(loadProductStyles());
      dispatch(loadProductCollections());
      dispatch(loadProductRootSections());
      dispatch(loadProductBrands());
      dispatch(loadProductCars());
      dispatch(loadProductCountries());
      const id = parseInt(ownProps.match.params.id || 0, 10);
      dispatch(loadOneProduct(id));
    },
    setDraftProductField(field, value) {
      dispatch(setDraftProductField(field, value));
    },
    uploadProductImage(data, id) {
      dispatch(uploadProductImage(data, id));
    },
    handleSave() {
      dispatch(saveProduct(ownProps.history));
    }
  })
)(ProductPage);
