const types = require('./types');

export const loadBannersData = () => ({
  type: types.LOAD_BANNERS_DATA
});

export const removeBanner = id => ({
  type: types.REMOVE_BANNER,
  id
});

export const setPage = page => ({
  type: types.SET_PAGE,
  page
});

export const loadOneBanner = id => ({
  type: types.LOAD_ONE_BANNER,
  id
});

export const loadBannerTypes = () => ({
  type: types.LOAD_BANNER_TYPES
});

export const uploadBannerImage = data => ({
  type: types.UPLOAD_BANNER_IMAGE,
  data
});

export const removeBannerHorizontalImage = id => ({
  type: types.REMOVE_BANNER_HORIZONTAL_IMAGE,
  id
})

export const removeBannerVerticalImage = id => ({
  type: types.REMOVE_BANNER_VERTICAL_IMAGE,
  id
})

export const setDraftBannerField = (field, value) => ({
  type: types.SET_DRAFT_BANNER_FIELD,
  field,
  value
});

export const saveBanner = history => ({
  type: types.SAVE_BANNER,
  history
});

export const toggleActivityType = id => ({
  type: types.TOGGLE_ACTIVITY_TYPE,
  id
});

export const toggleType = id => ({
  type: types.TOGGLE_TYPE,
  id
});
