import React from 'react';
import {Row, Cell, Block} from '../../../../components/SubLayout';
import moment from 'moment';


class GeneralInfo extends React.PureComponent {
  render() {
    const order = this.props.order;
    return (
      <Block title="Информация о заказе">
        <Row>
          <Cell>ИД заказа</Cell>
          <Cell>
            <strong>{order.ID || ''}</strong>
          </Cell>
        </Row>
        <Row>
          <Cell>Дата и время создания</Cell>
          <Cell>{moment(order.DATE_INSERT).format('DD.MM.YYYY HH:mm:ss')}</Cell>
        </Row>
        <Row>
          <Cell>Дата и время изменения</Cell>
          <Cell>{moment(order.DATE_UPDATE).format('DD.MM.YYYY HH:mm:ss')}</Cell>
        </Row>
        <Row>
          <Cell>Дата и время обратного звонка</Cell>
          <Cell>{order.CB_DATETIME}</Cell>
        </Row>
        <Row>
          <Cell>Менеджер-приемщик</Cell>
          <Cell>{order.CREATOR_TITLE}</Cell>
        </Row>
      </Block>
    );
  }
}

export default GeneralInfo;
