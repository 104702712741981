import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Button from 'material-ui/Button';
import Menu, {MenuItem, MenuList } from 'material-ui/Menu';
import common from 'material-ui/colors/common';
import grey from 'material-ui/colors/grey';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import R from 'ramda';
import {loadMenu} from '../modules/menu';
const {white} = common;


const menuButtonStyle = {
  color: white
};

const menuItemLinkStyle = {
  color: grey[800],
  textDecoration: 'none'
};

const menuContainer = {
  display: 'flex'
};


class TopMenu extends React.PureComponent {

  state = {
    selected: -1,
  };

  memoizedMenuSelect = R.memoize(index => event => {
    this.setState({
      selected: index,
      anchorEl: event.currentTarget
    });
  });

  componentDidMount() {
    this.props.loadMenu();
  }

  handleChange = (event, index, value) => {
    this.setState({value});
  }

  handleMenuClose = () => {
    this.setState({selected: -1});
  };

  render() {
    return (
      <div style={menuContainer}>
        {this.props.items.filter(item => item.children).map((item, index) => (
          <div key={index}>
            <Button
             // onTouchTap={this.memoizedMenuSelect(index)}
                onClick={this.memoizedMenuSelect(index)}
              style={menuButtonStyle}
            >{item.name}</Button>

            <Menu
              open={index === this.state.selected}
         //     onRequestClose={this.handleMenuClose}
              anchorEl={this.state.anchorEl}
            >
              <ClickAwayListener onClickAway={this.handleMenuClose}>
                <MenuList>
              {item.children.map((child, childIndex) =>
                <MenuItem key={childIndex}  onClick={this.handleMenuClose}>
                  {child.route ?
                    <Link to={child.route} style={menuItemLinkStyle}>{child.name}</Link>
                    :
                    <a href={child.url} style={menuItemLinkStyle}>{child.name}</a>
                  }
                </MenuItem>
              )}
                  </MenuList>
              </ClickAwayListener >
            </Menu>

          </div>
        ))}
      </div>
    );
  }
}


export default connect(
  state => ({
    items: state.menu.items
  }),
  (dispatch, ownProps) => ({
    loadMenu() {
      dispatch(loadMenu());
    }
  })
)(TopMenu);
