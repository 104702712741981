import React from 'react';
import Layout from '../../../components/Layout';
import Table, { TableHead, TableBody } from 'material-ui/Table';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import OrdersListSidebar from './OrdersListSidebar';
import Pagination from '../../../components/Pagination';
import OrdersListRow from '../components/OrdersListRow';
import OrdersListHeaderRow from '../components/OrdersListHeaderRow';
import SmartFab from '../../../components/SmartFab';
import Loader from '../../../components/Loader';
import { setPage, loadOrders, createOrderById, checkNewOrder, loadLastOrderId, ordersExport, emptyExportLink } from '../../../modules/orders/index';

const {
    getOrdersList,
    getOrdersLoading,
    getOrdersLoaded,
    getOrdersPage,
    getOrdersPageSize,
    getOrdersTotal,
    getOrdersExport
} = require('../../../selectors').default;



const topStyle = {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    paddingTop: '20px'
};

const paginationContainerStyle = {
    flex: '0 1 auto'
};

const refresherContainerStyle = {
    flex: 'none',
    margin: '0 20px'
};

const buttonsContainerStyle = {
    flex: '0 1 auto'
};

const orderButtonStyle = {
    marginBottom: 5,
    marginLeft: 10
};

const notFoundStyle = {
    width: '100%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    marginTop: -10
};

class OrdersListPage extends React.PureComponent {

    intervalId = null;

    componentDidMount() {
        this.props.loadOrders();
        this.props.loadLastOrderId();

        this.initOrdersNotifications();
    }

    initOrdersNotifications() {
        Notification.requestPermission((permission) => { });

        this.intervalId = setInterval(() => {
            this.props.checkNewOrder();
        }, 20000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        if (!this.props.loaded && !this.props.loading) {
            return null;
        }
        return (<
            Layout content={this.props.loading && this.props.orders.length === 0 ? < Loader /> : this.renderContent()}
            sidebar={this.props.loaded && < OrdersListSidebar />}
        />
        );
    }

    renderContent() {
        /*
        if (this.props.orders.length === 0) {
            return (<div style={notFoundStyle} >Заказы не найдены </div>);
        }
        */

        return (
            <div>
                <div style={topStyle} >
                    {this.renderPagination()} {this.renderRefresher()} {this.renderButtons()}
                </div>
                {(this.props.orders.length === 0) ? (<div style={notFoundStyle} >Заказы не найдены </div>) : this.renderTable()}
            </div>
        );
    }

    renderPagination() {
        return (<
            div style={paginationContainerStyle} >
            <
                Pagination total={this.props.total}
                current={this.props.page}
                pageSize={this.props.pageSize}
                onChange={this.props.onPageChange}
            /> < /
            div >
        );
    }

    renderRefresher() {
        return ( <
            div style={refresherContainerStyle} >
                <
                    SmartFab processing={this.props.loading}
                    onClick={this.props.loadOrders}
                    icon={'refresh'}
                /> < /
            div >
        );
    }

    renderButtons() {
        return ( <
            div style={buttonsContainerStyle} >
                    <
            Button raised color="primary"
                        onClick={this.props.onOrdersExport}
                        style={orderButtonStyle} >
                        Экспорт < Icon className="material-icons" > file_download < /Icon></Button > {
                            this.props.ordersExport.link && this.props.ordersExport.sent &&
                            <
                a href={this.props.ordersExport.link}
                                onClick={this.props.emptyExportLink} > Скачать < /a>
            } <
            Button raised color="primary"
                                    onClick={this.props.onOrderCreate}
                                    style={orderButtonStyle} >
                                    Заказ < Icon className="material-icons" > note_add < /Icon></Button >
                                    <
            Button raised color="primary"
                                        onClick={this.props.onReturnCreate}
                                        style={orderButtonStyle} >
                                        Возврат < Icon className="material-icons" > note_add < /Icon></Button >
                                        <
            /div>
        );
    }

    renderTable() {
        return ( <
            Table >
                                            <
            TableHead >
                                                <
                                                    OrdersListHeaderRow />
                                                <
            /TableHead> <
            TableBody > {
                                                        this.props.orders.map((order, index) =>
                                                            <
                                                                OrdersListRow key={index}
                                                                order={order}
                                                                onOpen={this.props.onOrderOpen}
                                                                onCopyToOrder={this.props.onCopyToOrder}
                                                                onCopyToReturn={this.props.onCopyToReturn}
                                                            />
                                                        )
                                                    } <
            /TableBody> < /
            Table >
        );
    }
}

export default withRouter(connect(
    state => ({
                                                        page: getOrdersPage(state),
        pageSize: getOrdersPageSize(state),
        total: getOrdersTotal(state),
        orders: getOrdersList(state),
        loading: getOrdersLoading(state),
        loaded: getOrdersLoaded(state),
        ordersExport: getOrdersExport(state)
    }),
    (dispatch, ownProps) => ({
                                                        onOrderOpen(order) {
                                                        ownProps.history.push(`/operator/orders/${order.ID}`);
        },
        loadLastOrderId() {
                                                        dispatch(loadLastOrderId());
        },
        checkNewOrder() {
                                                        dispatch(checkNewOrder());
        },
        loadOrders() {
                                                        dispatch(loadOrders());
        },
        onPageChange(number) {
                                                        dispatch(setPage(number));
            dispatch(loadOrders());
        },
        onOrderCreate() {
                                                        ownProps.history.push('/operator/orders/new/1');
        },
        onReturnCreate() {
                                                        ownProps.history.push('/operator/orders/new/2');
        },
        onCopyToOrder(order) {
                                                        dispatch(createOrderById(ownProps.history, order.ID, 1));
        },
        onCopyToReturn(order) {
                                                        dispatch(createOrderById(ownProps.history, order.ID, 2));
        },
        onOrdersExport() {
                                                        dispatch(ordersExport());
        },
        emptyExportLink() {
                                                        dispatch(emptyExportLink());
        }
    })
)(OrdersListPage));