import { TextField } from "@material-ui/core";
import Button from "material-ui/Button";
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "material-ui/Table";
import React, { useState } from "react";
import { connect } from "react-redux";

import { Block, Row } from "../../../components/SubLayout";
import { setOtzyv } from "../../../modules/products/index";

const ProductComments = ({ product, setOtzyvLocal, loadOneProductRemains }) => {
  const [state, setState] = useState({ text: "" });
  const { otzyvy } = (product && product.otzyvy) || { otzyvy: [] };
  const addOtzyv = () => {
    setOtzyvLocal(product.ID, state.text);
    loadOneProductRemains();
  };

  const style = {
    textField: {
      cursor: "pointer",
      fontSize: 13,
      width: "calc(100% - 25px)",
    },
    list: {
      position: "absolute",
      background: "white",
      zIndex: 1000,
      maxHeight: 130,
      border: "1px solid lightgrey",
      borderTop: "none",
      overflowY: "auto",
    },
    container: {
      position: "relative",
    },
  };

  const onTextChange = (e) => {
    setState({
      text: e.target.value,
    });
  };

  const renderOtzyv = () => (
    <Row>
      <TextField
        style={style.textField}
        label="Отзыв"
        onChange={onTextChange}
        value={state.text}
        multiline
        rows={3}
      />
      <Button disabled={!state.text} onClick={addOtzyv}>
        Добавить
      </Button>
    </Row>
  );

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Отзывы</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderOtzyvRow = (otzyv, index) => {
    const isEnabled = otzyv !== "";
    return (
      <TableRow key={index}>
        <TableCell>{otzyv}</TableCell>
      </TableRow>
    );
  };
  const renderTable = () => (
    <TableBody>
      {otzyvy.map((otzyv, index) => renderOtzyvRow(otzyv, index))}
    </TableBody>
  );

  return (
    <Block title="Комментарии">
      <Table>
        {renderHeader()}
        {renderTable()}
      </Table>
      {renderOtzyv()}
    </Block>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    setOtzyvLocal(productId, text) {
      dispatch(setOtzyv(productId, text));
    },
  })
)(ProductComments);
