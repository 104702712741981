import R from 'ramda';


const types = require('./types');

const initialState = {
  products: [],
  product: [],
  productRemains: [],
  productSearch: [],
  search: '',
  styles: [],
  collections: [],
  rootSections: [],
  categories: [],
  brands: [],
  cars: [],
  countries: []
};

export default function (state = initialState, action) {
  switch(action.type) {
    case types.SET_SEARCH:
      return {...state, search: action.search};
    case types.LOAD_PRODUCTS:
      return {...state};
    case types.LOAD_PRODUCTS_COMPLETED:
      return {...state, products: action.products};
    case types.LOAD_ONE_PRODUCT_COMPLETED:
      return {...state, product: action.product};
    case types.LOAD_ONE_PRODUCT_REMAINS_COMPLETED:
      return {...state, productRemains: action.productRemains};
    case types.LOAD_PRODUCT_STYLES_COMPLETED:
      return {...state, styles: action.styles};
    case types.LOAD_PRODUCT_COLLECTIONS_COMPLETED:
      return {...state, collections: action.collections};
    case types.LOAD_PRODUCT_ROOTSECTIONS_COMPLETED:
      return {...state, rootSections: action.rootSections};
    case types.LOAD_PRODUCT_CATEGORIES_COMPLETED:
      return {...state, categories: action.categories};
    case types.LOAD_PRODUCT_BRANDS_COMPLETED:
      let brands = action.brands;
      brands[0] = R.assocPath(['name'], 'Нет', brands[0]);
      return {...state, brands};
    case types.LOAD_PRODUCT_CARS_COMPLETED:
      let cars = R.prepend({'id': 0, 'name': 'Без номера машины'}, action.cars);
      return {...state, cars};
    case types.LOAD_PRODUCT_COUNTRIES_COMPLETED:
      let countries = R.prepend({'id': 0, 'name': 'Нет'}, action.countries);
      return {...state, countries};
    case types.SET_REMAINS_ENABLED:
      return {
        ...state,
        productRemains: state.productRemains.map(row => {
          if (row.stock_id === action.stockId && row.size_name === action.sizeName) {
            return {...row, is_enabled: '1'};
          } else {
            return row;
          }
        })
      };
    case types.SET_REMAINS_DISABLED:
      return {
        ...state,
        productRemains: state.productRemains.map(row => {
          if (row.stock_id === action.stockId && row.size_name === action.sizeName) {
            return {...row, is_enabled: '0'};
          } else {
            return row;
          }
        })
      };
    case types.SET_DRAFT_PRODUCT_FIELD:
      return R.assocPath(['product', ...action.field], action.value, state);
    case types.UPLOAD_PRODUCT_IMAGE_COMPLETED:
      return R.assocPath(['product', 'PICTURE_DATA', 'big'], action.image, state);
    default:
      return state;
  }
}
