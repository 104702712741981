import React from 'react';
import {TableRow, TableCell} from 'material-ui/Table';


class CatalogListHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableRow>
        <TableCell>
        </TableCell>
        <TableCell>
          ИД
        </TableCell>
        <TableCell>
          Фото
        </TableCell>
        <TableCell>
          Артикул/Бренд/Сезон/Цвет
        </TableCell>
        <TableCell>
          Раздел
        </TableCell>
      </TableRow>
    );
  }
}

export default CatalogListHeaderRow;
