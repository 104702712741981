export const LOAD_BANNERS_DATA_WC = 'bannerswc/LOAD_BANNERS_DATA_WC';
export const LOAD_BANNERS_DATA_COMPLETED = 'bannerswc/LOAD_BANNERS_DATA_COMPLETED';

export const LOAD_ONE_BANNER = 'bannerswc/LOAD_ONE_BANNER';
export const LOAD_ONE_BANNER_COMPLETED = 'bannerswc/LOAD_ONE_BANNER_COMPLETED';

export const REMOVE_BANNER = 'bannerswc/REMOVE_BANNER';

export const SET_PAGE = 'bannerswc/SET_PAGE';

export const LOAD_BANNER_TYPES = 'bannerswc/LOAD_BANNER_TYPES';
export const LOAD_BANNER_TYPES_COMPLETED = 'bannerswc/LOAD_BANNER_TYPES_COMPLETED';

export const UPLOAD_BANNER_IMAGE = 'bannerswc/UPLOAD_BANNER_IMAGE';

export const SET_DRAFT_BANNER_FIELD = 'bannerswc/SET_DRAFT_BANNER_FIELD';

export const SAVE_BANNER = 'bannerswc/SAVE_BANNER';

export const TOGGLE_ACTIVITY_TYPE = 'bannerswc/TOGGLE_ACTIVITY_TYPE';
export const TOGGLE_TYPE = 'bannerswc/TOGGLE_TYPE';

export const REMOVE_BANNER_HORIZONTAL_IMAGE = 'bannerswc/REMOVE_BANNER_HORIZONTAL_IMAGE';
export const REMOVE_BANNER_VERTICAL_IMAGE = 'bannerswc/REMOVE_BANNER_VERTICAL_IMAGE';
