import React from 'react';
import { connect } from 'react-redux';
import Filter from '../../../../components/Filter';
import {
  loadBannersDataWc,
  toggleActivityType,
  toggleType,
  loadBannerTypesWc
} from '../../../../modules/marketer/bannerswc/index';
const {
  getBannersActivityTypesSelectedWc,
  getBannersTypesSelectedWc,
  getBannerTypesWc
} = require('../../../../selectors').default;

const bannerActivityFilterOptions = [
  { id: '0', name: 'Нет' },
  { id: '1', name: 'Да' }
];

class BannerswcListSidebar extends React.PureComponent {
  componentDidMount() {
    this.props.loadBannerTypesWc();
  }
  render() {
    return (
      <div>
        {this.renderActivityFilter()}
        {this.renderBannerTypesFilter()}
      </div>
    );
  }
  renderActivityFilter() {
    return <Filter
      label="Активность"
      items={bannerActivityFilterOptions}
      selected={this.props.activityTypesSelected}
      onToggle={this.props.toggleActivityType}
    />;
  }
  renderBannerTypesFilter() {
    return <Filter
      label="Тип"
      items={this.props.types}
      selected={this.props.typesSelected}
      onToggle={this.props.toggleType}
    />;
  }
}

export default connect(
  state => ({
    activityTypesSelected: getBannersActivityTypesSelectedWc(state),
    typesSelected: getBannersTypesSelectedWc(state),
    types: getBannerTypesWc(state)
  }),
  dispatch => ({
    loadBannerTypesWc() {
      dispatch(loadBannerTypesWc());
    },
    toggleActivityType(id) {
      dispatch(toggleActivityType(id));
      dispatch(loadBannersDataWc());
    },
    toggleType(id) {
      dispatch(toggleType(id));
      dispatch(loadBannersDataWc());
    }
  })
)(BannerswcListSidebar);
