import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from 'material-ui/styles';
import FormControl from 'material-ui/Form/FormControl';
import Input from 'material-ui/Input';
import {setProductsSearch} from '../../../modules/products';

const {getCatalogProductsSearch} = require('../../../selectors').default;


const styleSheet = theme => ({
  formControl: {
    fontSize: 13,
    width: '100%'
  },
  inputLabel: {
    transform: 'none !important'
  }
});

const inputStyleSheet = theme => ({
  root: {
    marginTop: '5px !important',
    width: '100%'
  }
});

const InputStyled = withStyles(inputStyleSheet, {name: 'Input'})(Input);

const CustomTextField = withStyles(styleSheet, {name: 'TextField'})(props => (
  <FormControl className={props.classes.formControl}>
    <InputStyled value={props.value} onChange={props.onChange} placeholder={props.label} />
  </FormControl>
));

class CatalogListSidebar extends React.PureComponent {
  handleSearchChange = e => {
    this.props.handleSearchChange(e.target.value);
  };

  render() {
    return (
      <div>
        {this.renderSearch()}
      </div>
    );
  }

  renderSearch() {
    return <CustomTextField
      label="Поиск"
      value={this.props.search}
      onChange={this.handleSearchChange}
    />;
  }
}

export default connect(
  state => ({
    search: getCatalogProductsSearch(state)
  }),
  dispatch => ({
    handleSearchChange(value) {
      dispatch(setProductsSearch(value));
    }
  })
)(CatalogListSidebar);
