import React from 'react';
import {connect} from 'react-redux';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import {signin} from '../modules/signin';
const {
  isAuthenticated
} = require('../selectors').default;


const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30
  },
  input: {
    width: 240,
    marginTop: 10
  },
  button: {
    marginTop: 30
  }
}

class LoginPage extends React.Component {
  constructor() {
    super();

    this.state = {
      login: '',
      password: ''
    };
  }

  onLoginChange = e => {
    this.setState({
      login: e.target.value
    });
  }

  onPasswordChange = e => {
    this.setState({
      password: e.target.value
    });
  }

  onSubmit = e => {
    e.preventDefault();

    this.props.signin(this.state.login, this.state.password);
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div style={style.container}>
          <h2>Авторизация</h2>

            <TextField label="Логин" value={this.state.login} onChange={this.onLoginChange} style={style.input} />

            <TextField label="Пароль" value={this.state.password} type="password" onChange={this.onPasswordChange} style={style.input} />

            <Button type="submit" raised color="primary" style={style.button} onClick={this.onSubmit}>Войти</Button>

            {this.props.success === false &&
              <p>
                Неверный логин или пароль
              </p>
            }
        </div>
      </form>
    )
  }
}


export default connect(
  state => ({
    success: isAuthenticated(state)
  }),
  (dispatch, ownProps) => ({
    signin(login, password) {
      dispatch(signin(ownProps.history, login, password));
    }
  })
)(LoginPage);
