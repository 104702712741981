import React from 'react';
import {Row, Cell, Block} from '../../../../components/SubLayout';
import TextField from 'material-ui/TextField';


const textFieldStyle = {
  fontSize: 13,
  width: '100%'
};

class UserAdditionalInfo extends React.PureComponent {
  render() {
    const order = this.props.order;
    const orderProps = order.props || {};

    return (
      <Block title="Дополнительные данные клиента">
        <Row>
          <Cell>
            <TextField label="E-mail" value={orderProps.EMAIL || ''} onChange={this.props.setFieldValue(['props', 'EMAIL'])} style={textFieldStyle}/>
          </Cell>
          <Cell>
            <TextField label="Мобильный телефон" value={orderProps.MOBILE || ''} onChange={this.props.setFieldValue(['props', 'MOBILE'])} style={textFieldStyle}/>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField label="Дополнительный телефон" value={orderProps.PHONE || ''} onChange={this.props.setFieldValue(['props', 'PHONE'])} style={textFieldStyle}/>
          </Cell>
          <Cell>
            <TextField label="Фамилия" value={orderProps.SECOND_NAME || ''} onChange={this.props.setFieldValue(['props', 'SECOND_NAME'])} style={textFieldStyle}/>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <TextField label="Имя" value={orderProps.NAME || ''} onChange={this.props.setFieldValue(['props', 'NAME'])} style={textFieldStyle}/>
          </Cell>
          <Cell>
            <TextField label="Отчество" value={orderProps.PATRONYMIC || ''} onChange={this.props.setFieldValue(['props', 'PATRONYMIC'])} style={textFieldStyle}/>
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default UserAdditionalInfo;
