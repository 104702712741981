import React from 'react';


class DateRangeFilter extends React.PureComponent {
  style = {
    display: 'flex',
    justifyContent: 'space-between'
  };

  render() {
    return (
      <div style={this.style}>
        {this.props.leftPicker}
        {this.props.rightPicker}
      </div>
    );
  }
}

export default DateRangeFilter;
