import R from 'ramda';


const uncurry = f => R.uncurryN(f.length + 1, f);

export const getProductsList = R.path(['products']);
export const getProductsById = uncurry(id => R.path(['byIds', id]));

export const getProduct = R.path(['product']);
export const getProductRemains = R.path(['productRemains']);

export const getCatalogProductsSearch = R.path(['search']);

export const getProductStyles = R.path(['styles']);
export const getProductCollections = R.path(['collections']);
export const getProductRootSections = R.path(['rootSections']);
export const getProductCategories = R.path(['categories']);
export const getProductBrands = R.path(['brands']);
export const getProductCars = R.path(['cars']);
export const getProductCountries = R.path(['countries']);
