export const LOAD_PRODUCTS = "products/LOAD_PRODUCTS";
export const LOAD_PRODUCTS_COMPLETED = "products/LOAD_PRODUCTS_COMPLETED";

export const LOAD_ONE_PRODUCT = "products/LOAD_ONE_PRODUCT";
export const LOAD_ONE_PRODUCT_COMPLETED = "products/LOAD_ONE_PRODUCT_COMPLETED";

export const LOAD_ONE_PRODUCT_REMAINS = "products/LOAD_ONE_PRODUCT_REMAINS";
export const LOAD_ONE_PRODUCT_REMAINS_COMPLETED =
  "products/LOAD_ONE_PRODUCT_REMAINS_COMPLETED";

export const LOAD_PRODUCT_STYLES = "products/LOAD_PRODUCT_STYLES";
export const LOAD_PRODUCT_STYLES_COMPLETED =
  "products/LOAD_PRODUCT_STYLES_COMPLETED";

export const LOAD_PRODUCT_COLLECTIONS = "products/LOAD_PRODUCT_COLLECTIONS";
export const LOAD_PRODUCT_COLLECTIONS_COMPLETED =
  "products/LOAD_PRODUCT_COLLECTIONS_COMPLETED";

export const LOAD_PRODUCT_ROOTSECTIONS = "products/LOAD_PRODUCT_ROOTSECTIONS";
export const LOAD_PRODUCT_ROOTSECTIONS_COMPLETED =
  "products/LOAD_PRODUCT_ROOTSECTIONS_COMPLETED";

export const LOAD_PRODUCT_CATEGORIES = "products/LOAD_PRODUCT_CATEGORIES";
export const LOAD_PRODUCT_CATEGORIES_COMPLETED =
  "products/LOAD_PRODUCT_CATEGORIES_COMPLETED";

export const LOAD_PRODUCT_BRANDS = "products/LOAD_PRODUCT_BRANDS";
export const LOAD_PRODUCT_BRANDS_COMPLETED =
  "products/LOAD_PRODUCT_BRANDS_COMPLETED";

export const LOAD_PRODUCT_CARS = "products/LOAD_PRODUCT_CARS";
export const LOAD_PRODUCT_CARS_COMPLETED =
  "products/LOAD_PRODUCT_CARS_COMPLETED";

export const LOAD_PRODUCT_COUNTRIES = "products/LOAD_PRODUCT_COUNTRIES";
export const LOAD_PRODUCT_COUNTRIES_COMPLETED =
  "products/LOAD_PRODUCT_COUNTRIES_COMPLETED";

export const SET_SEARCH = "products/SET_SEARCH";

export const SET_REMAINS_ENABLED = "products/SET_REMAINS_ENABLED";
export const SET_REMAINS_DISABLED = "products/SET_REMAINS_DISABLED";

export const SET_OTZYV = "products/SET_OTZYV";

export const SET_DRAFT_PRODUCT_FIELD = "products/SET_DRAFT_PRODUCT_FIELD";

export const UPLOAD_PRODUCT_IMAGE = "products/UPLOAD_PRODUCT_IMAGE";
export const UPLOAD_PRODUCT_IMAGE_COMPLETED =
  "products/UPLOAD_PRODUCT_IMAGE_COMPLETED";

export const SAVE_PRODUCT = "products/SAVE_PRODUCT";
