import React from 'react';
import {connect} from 'react-redux';
import {TableRow} from 'material-ui/Table';
import {default as TableCell} from './OrdersTableCell';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import {addToDraftBasket} from '../../../modules/orders';


const sectionStyle = {
  marginRight: 10
};

const sizeContainerStyle = {
  marginRight: 15,
  display: 'flex',
  alignItems: 'center',
  float: 'left'
};

const sizeStyle = {
  width: 50
};

const imgStyle = {
  width: 100
};

class ProductRow extends React.Component {
  render() {
    const product = this.props.product;

    return (
      <TableRow>
        <TableCell>{product.ID}</TableCell>
        <TableCell><img alt="" style={imgStyle} src={product.PREVIEW_PICTURE_URL || ''} /></TableCell>
        <TableCell>
          {product.PROPERTIES.ARTICUL.print_value}<br/>
          {product.PROPERTIES.BRAND.print_value}<br/>
          {product.PROPERTIES.COLLECTION.print_value}
        </TableCell>
        <TableCell>{product.PROPERTIES.COLOR.print_value}</TableCell>
        <TableCell>
          {(product.SECTION_NAMES || []).map((name, index) =>
            <span key={index} style={sectionStyle}>{name}</span>
          )}
        </TableCell>
        <TableCell>
          {product.ALL_SIZES[0] === '' ?
            <IconButton
              color="accent"
              onClick={() => this.props.handleAddToBasket()}
            >
              <Icon className="material-icons">add_shopping_cart</Icon>
            </IconButton>
            :
            Object.keys(product.TRADEX_REMAINS_BY_SIZES).map(size =>
              <span
                key={size}
                style={sizeContainerStyle}
              >
                <span style={sizeStyle}>{size} ({product.TRADEX_REMAINS_BY_SIZES[size]})</span>
                <IconButton
                  color="accent"
                  onClick={() => this.props.handleAddToBasket(size)}
                >
                  <Icon className="material-icons">add_shopping_cart</Icon>
                </IconButton>
              </span>
            )
          }
        </TableCell>
      </TableRow>
    );
  }
}


export default connect(
  state => ({}),
  (dispatch, ownProps) => ({
    handleAddToBasket(size) {
      dispatch(addToDraftBasket(ownProps.product.ID, size))
    }
  })
)(ProductRow);
