import React from 'react';
import List from 'material-ui/List';
import FilterItem from './FilterItem';


class Filter extends React.PureComponent {

  styleSubheader = {
    color: 'rgba(0, 0, 0, 0.54)',
		fontSize: 12
  };

  styleList = {
    paddingTop: 4,
    paddingBottom: 4
  };

  render() {
    return (
      <List style={this.styleList}>
        <span style={this.styleSubheader}>{this.props.label}</span>
        {this.props.items.map((item, index) =>
          <FilterItem
            item={item}
            key={index}
            onChange={this.props.onToggle}
            checked={this.props.selected && this.props.selected.indexOf(item.id) !== -1}
          />
        )}
      </List>
    );
  }
}

export default Filter;
