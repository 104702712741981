import React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import Layout from '../../../../components/Layout';
import Button from 'material-ui/Button';
import BannerwcLayout from '../components/BannerwcLayout';
import {
  loadOneBanner,
  loadBannerTypesWc,
  uploadBannerImage,
  setDraftBannerField,
  saveBanner,
  removeBanner,
  removeBannerHorizontalImage,
  removeBannerVerticalImage
} from '../../../../modules/marketer/bannerswc';

const {
  getBannerWc,
  getBannerTypesWc
} = require('../../../../selectors').default;


const styles = {
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  title: {
    padding: '0 5px',
    margin: 0
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 10px'
  },
  bottom: {
    flex: 'none',
    padding: '7px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginLeft: 10
  }
};

class BannerwcPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadOneBanner();
  }

  handleClose = () => {
    this.props.history.push('/marketer/bannerswc');
  }

  setFieldValue = R.memoizeWith(path => JSON.stringify(path), path => event => {
    this.props.setDraftBannerField(path, event.target ? event.target.value : event);
  });

  render() {
    return (
      <Layout content={
        <div style={styles.root}>
          <h3 style={styles.title}>
            Баннер № {this.props.bannerwc.id}
          </h3>
          <div style={styles.content}>
            {this.renderBanner()}
          </div>
          <div style={styles.bottom}>
            <Button raised color="default" style={styles.button} onClick={this.props.handleRemove}>Удалить</Button>
            <Button raised color="accent" style={styles.button} onClick={this.handleClose}>Закрыть</Button>
            <Button raised color="primary" style={styles.button} onClick={this.props.handleSave}>Сохранить</Button>
          </div>
        </div>
      } />
    );
  }

  renderBanner() {
    return (
      <div>
        <BannerwcLayout
          bannerwc={this.props.bannerwc}
          types={this.props.bannerwcTypes}
          uploadImage={this.props.uploadBannerImage}
          setFieldValue={this.setFieldValue}
          removeBannerHorizontalImage={this.props.removeBannerHorizontalImage}
          removeBannerVerticalImage={this.props.removeBannerVerticalImage}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    bannerwc: getBannerWc(state),
    bannerwcTypes: getBannerTypesWc(state)
  }),
  (dispatch, ownProps) => ({
    loadOneBanner() {
      const id = parseInt(ownProps.match.params.id || 0, 10);
      dispatch(loadBannerTypesWc());
      dispatch(loadOneBanner(id));
    },
    uploadBannerImage(data) {
      dispatch(uploadBannerImage(data));
    },
    setDraftBannerField(field, value) {
      dispatch(setDraftBannerField(field, value));
    },
    handleSave() {
      dispatch(saveBanner(ownProps.history));
    },
    handleRemove() {
      if (window.confirm('Вы уверены, что хотите удалить баннер?')) {
        dispatch(removeBanner(ownProps.match.params.id));
        ownProps.history.push('/marketer/bannerWC');
      }
    },
    removeBannerHorizontalImage() {
      if (window.confirm('Вы уверены, что хотите удалить горизонтальное изображение?')) {
        dispatch(removeBannerHorizontalImage(ownProps.match.params.id))
      }
    },
    removeBannerVerticalImage() {
      if (window.confirm('Вы уверены, что хотите удалить вертикальное изображение?')) {
        dispatch(removeBannerVerticalImage(ownProps.match.params.id))
      }
    }
  })
)(BannerwcPage);
