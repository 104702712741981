import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Icon from 'material-ui/Icon';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import blue from 'material-ui/colors/blue';
import common from 'material-ui/colors/common';
import TopMenu from './TopMenu';
import {signout} from '../modules/signin';
import {withRouter} from 'react-router';
const {isAuthenticated} = require('../selectors').default;
const {black} = common;


const toolbarStyle = {
  backgroundColor: blue[500],
  flex: 'none'
};

const homeLinkStyle = {
  color: black
};


class Header extends React.PureComponent {

  render() {
    return (
      <Toolbar style={toolbarStyle}>
        <Link to="/" style={homeLinkStyle}><Icon className="material-icons">apps</Icon></Link>
        {this.props.isAuthenticated &&
          <TopMenu />
        }
        <div style={{flex: 1}} />
        {this.props.isAuthenticated &&
          <IconButton onClick={this.props.signout}>
            <Icon className="material-icons">exit_to_app</Icon>
          </IconButton>
        }
      </Toolbar>
    );
  }
}


export default withRouter(connect(
  state => ({
    isAuthenticated: isAuthenticated(state)
  }),
  (dispatch, ownProps) => ({
    signout() {
      dispatch(signout(ownProps.history));
    }
  })
)(Header));
