import {put, all, takeLatest} from 'redux-saga/effects';
import axios from 'axios';


export const RUN_EXPORT = 'exporting/RUN';
export const SET_LINK = 'exporting/SET_LINK';


const initialState = {
  processing: false,
  processed: false,
  link: ''
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RUN_EXPORT:
      return {...state, processing: true, processed: false, link: ''};
    case SET_LINK:
      return {...state, processing: false, processed: true, link: action.link};
    default:
      return state;
  }
}

export const exportProducts = () => ({type: RUN_EXPORT});


function* exportProductsSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/catalog/product/export'
  });

  yield put({type: SET_LINK, link: result.data});
}

export function* saga() {
  yield all([
    takeLatest(RUN_EXPORT, exportProductsSaga),
  ]);
}
