import React from 'react';
import {Block, Row, Cell} from '../../../components/SubLayout';
import TextField from 'material-ui/TextField';


class Info extends React.PureComponent {
  createPath(prop) {
    return ['PROPERTIES', prop, 'print_value'];
  }

  render() {
    const product = this.props.product;
    const productProps = product.PROPERTIES || {};

    return (
      <Block title="Параметры">
        <Row>
          <Cell>Артикул поставщика:</Cell>
          <Cell>
            <TextField
              value={(productProps.PROVIDER_ARTICLE && productProps.PROVIDER_ARTICLE.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('PROVIDER_ARTICLE'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Артикул:</Cell>
          <Cell>
            <TextField
              value={(productProps.ARTICUL && productProps.ARTICUL.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('ARTICUL'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Цвет:</Cell>
          <Cell>
            <TextField
              value={(productProps.COLOR && productProps.COLOR.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('COLOR'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Цвет (латиница):</Cell>
          <Cell>
            <TextField
              value={(productProps.COLOR_LATIN && productProps.COLOR_LATIN.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('COLOR_LATIN'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Материал верха:</Cell>
          <Cell>
            <TextField
              value={(productProps.TOP_MATERIAL && productProps.TOP_MATERIAL.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('TOP_MATERIAL'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Материал подкладки:</Cell>
          <Cell>
            <TextField
              value={(productProps.LINING_MATERIAL && productProps.LINING_MATERIAL.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('LINING_MATERIAL'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Подошва:</Cell>
          <Cell>
            <TextField
              value={(productProps.SOLE && productProps.SOLE.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('SOLE'))}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Размер каблука:</Cell>
          <Cell>
            <TextField
              value={(productProps.HEEL && productProps.HEEL.print_value) || ''}
              onChange={this.props.setFieldValue(this.createPath('HEEL'))}
            />
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default Info;
