import { put, takeEvery, select, all, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from 'axios';
import qs from 'qs';
import R from 'ramda';
import { setDraftOrderField } from './actions';
const types = require('./types');
const {
    getDeliveries,
    getOrdersPage,
    getOrdersPageSize,
    getOrderSourceById,
    getOrderSourcesSelected,
    getOrderAggregatedByValue,
    getOrderAggregatedSelected,
    getOrderTypeById,
    getOrderTypesSelected,
    getOrderStatusById,
    getOrderStatusesSelected,
    getOrderSourceStockId,
    getOrdersSearch,
    getOrdersSearchArticle,
    getOrdersSearchSize,
    getOrdersDateDeliveryFrom,
    getOrdersDateDeliveryTo,
    getOrdersDateCreateFrom,
    getOrdersDateCreateTo,
    getOrderDraftOrder,
    getLastOrderId,
    getOrderDraftProductsSearch,
    getOrdersExport
} = require('../../selectors').default;


function* saveOrderSaga({ history, id }) {
    const state = yield select();
    const order = getOrderDraftOrder(state);
    // const user = getOrderDraftUser(state);

    let result;

    console.log('saving...');

    try {
        result = yield axios({
            method: 'post',
            url: '/api/operator/order/save?json',
            data: qs.stringify({
                ...order,
                USER_DATA: {
                    PERSONAL_MOBILE: '',
                    EMAIL: '',
                    CARD_CODE: '',
                    ...order.USER_DATA
                }
            }),
            withCredentials: true
        });
    } catch (e) {
        yield put({ type: types.SAVE_ORDER_ERROR, error: e.response.data })
        return;
    }

    console.log('result: ', result);

    if (order.ID) {
        yield put({ type: types.SAVE_ORDER_COMPLETED, order: result.data });
        yield put({ type: types.LOAD_ONE_ORDER_COMPLETED, order: result.data });
        yield loadUser({ id: result.data.USER_ID });
        yield put({ type: types.LOAD_ONE_ORDER_COMPLETED_FULLY });
    } else {
        yield put({ type: types.LOAD_ONE_ORDER, id: result.data.ID });
        history.push(`/operator/orders/${result.data.ID}`);
    }
}

function* checkNewOrderSaga() {
    let state = yield select();
    const previousLastId = getLastOrderId(state);

    yield loadLastOrderIdSaga();

    state = yield select();
    const newOrderId = getLastOrderId(state);

    if (previousLastId === newOrderId) {
        return;
    }

    Notification.requestPermission((permission) => {
        let title = 'Создан новый ЗАКАЗ №' + newOrderId + '!';
        let notification = new Notification(title, {
            body: ''
        });
        notification.onclick = function (x) {
            window.focus();
            this.close();
        };
        setTimeout(() => {
            notification.close();
        }, 2000);
    });

    yield loadOrdersSaga();
}

function* calcPricesSaga({ id }) {
    const result = yield axios({
        url: '/api/operator/order/updatePrice',
        method: 'post',
        data: qs.stringify({
            orderId: id
        })
    });

    const order = result.data;

    yield put({ order, type: types.LOAD_ONE_ORDER_COMPLETED });
    yield loadUser({ id: order.USER_ID });
    yield put({ type: types.LOAD_ONE_ORDER_FULLY });

    yield put({ type: types.CALC_PRICES_COMPLETED });
    yield call(delay, 2000);
    yield put({ type: types.CALC_PRICES_COMPLETED_FULLY });
}

function* loadLastOrderIdSaga() {
    const result = yield axios({
        url: '/api/operator/order/lastId'
    });
    const lastId = parseInt(result.data, 10);

    yield put({ lastId, type: types.LOAD_LAST_ORDER_ID_COMPLETED });
}

function* sendConfirmationEmailSaga({ id }) {
    yield axios({
        url: `/api/operator/order/emailConfirmed?id=${id}`
    });

    yield put({ type: types.SEND_CONFIRMATION_EMAIL_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_CONFIRMATION_EMAIL_COMPLETED_FULLY, id });
}

function* sendConfirmationSmsSaga({ id }) {
    yield axios({
        url: `/api/operator/order/smsConfirmed?id=${id}`
    });

    yield put({ type: types.SEND_CONFIRMATION_SMS_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_CONFIRMATION_SMS_COMPLETED_FULLY, id });
}

function* sendEcardSmsSaga({ id }) {
    yield axios({
        url: `/api/operator/order/ecardSms?id=${id}`
    });

    yield put({ type: types.SEND_ECARD_SMS_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_ECARD_SMS_COMPLETED_FULLY, id });
}

function* sendDelivSmsSaga({ id }) {
    yield axios({
        url: `/api/operator/order/delivSms?id=${id}`
    });

    yield put({ type: types.SEND_DELIV_SMS_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_DELIV_SMS_COMPLETED_FULLY, id });
}

function* viewPaymentUrlSaga({ id }) {
    const result = yield axios({
        url: '/api/operator/order/getPaymentUrl',
        method: 'post',
        data: qs.stringify({
            orderId: id
        })
    });

    const { url } = result.data

    alert(url)
}

function* sendPaymentUrlSaga({ id }) {
    yield axios({
        url: '/api/operator/order/paymentUrl',
        method: 'post',
        data: qs.stringify({
            orderId: id
        })
    });

    yield put({ type: types.SEND_PAYMENT_URL_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_PAYMENT_URL_COMPLETED_FULLY, id });
}

function* sendPaymentSmsSaga({ id }) {
    yield axios({
        url: '/api/operator/order/paymentSms',
        method: 'post',
        data: qs.stringify({
            orderId: id
        })
    });

    yield put({ type: types.SEND_PAYMENT_SMS_COMPLETED, id });

    yield call(delay, 2000);

    yield put({ type: types.SEND_PAYMENT_SMS_COMPLETED_FULLY, id });
}

function* ordersExportSaga() {
    const state = yield select();
    const page = getOrdersPage(state);
    const pageSize = getOrdersPageSize(state);
    const search = getOrdersSearch(state);
    // const qw = getOrderDraftOrdersExport(state);


    const getValues = (getById, getSelected) => R.map(id => getById(id, state).name, getSelected(state));
    const result = yield axios({
        url: '/api/operator/order/ordersExport',
        method: 'post',
        params: R.pickBy((val, key) => !!val, {
            page,
            page_size: pageSize,
            sourceStock: getOrderSourceStockId(state),
            searchValues: search ? [search] : undefined,
            article: getOrdersSearchArticle(state),
            size: getOrdersSearchSize(state),
            deliveryDateFrom: getOrdersDateDeliveryFrom(state),
            deliveryDateTo: getOrdersDateDeliveryTo(state),
            insertFromDate: getOrdersDateCreateFrom(state),
            insertToDate: getOrdersDateCreateTo(state),
            tagsData: {
                sourceName: {
                    values: getValues(getOrderSourceById, getOrderSourcesSelected)
                },
                aggregatedName: {
                    values: getValues(getOrderAggregatedByValue, getOrderAggregatedSelected)
                },
                typeName: {
                    values: getValues(getOrderTypeById, getOrderTypesSelected)
                },
                statusName: {
                    values: getValues(getOrderStatusById, getOrderStatusesSelected)
                }
            }
        })
    });
    yield put({ type: types.ORDERS_EXPORT_COMPLETED });

    yield call(delay, 2000);

    yield put({ type: types.ORDERS_EXPORT_COMPLETED_FULLY, link: result.data.url });

    // const qw = getOrdersExport(state);

    //yield put({type: types.ORDERS_EXPORT_SET_LINK, link: result.data.url});

    // const url = result.data.url;
    //console.log(result.data.url+' - '+JSON.stringify(qw));
    console.log(result.data.url);

}

function* emptyExportLinkSaga() {
    const state = yield select();
    const ordersExport = getOrdersExport(state)
    //yield call(delay, 2000);
    if (ordersExport.sent) {
        yield put({ type: types.ORDERS_EXPORT_UNSET_LINK, link: null });
    }

}

function* loadBasketStatusesSaga({ typeId }) {
    typeId = typeId || 1;
    const result = yield axios({
        url: `/api/operator/basketStatus/index?typeId=${typeId}`
    });
    yield put({ items: result.data, type: types.LOAD_BASKET_STATUSES_COMPLETED });
}

function* loadOrderStatusesSaga() {
    const result = yield axios({
        url: '/api/operator/order/statuses'
    });
    yield put({ items: result.data, type: types.LOAD_ORDER_STATUSES_COMPLETED });
}

function* loadOneOrderSaga({ id, typeId }) {
    let order;
    if (id === 0) {
        const result = yield axios({
            method: 'post',
            url: `/api/operator/order/create?json`,
            data: qs.stringify(({
                TYPE_ID: typeId || 1
            }))
        });
        order = { ...result.data, USER_DATA: {} };
    } else {
        const result = yield axios({
            url: `/api/operator/order/one?id=${id}`
        });
        order = result.data;
    }
    yield put({ order, type: types.LOAD_ONE_ORDER_COMPLETED });
}

function* loadDeliveriesSaga({ typeId }) {
    const result = yield axios({
        url: `/api/operator/delivery/list?orderTypeId=${typeId}`
    });

    yield put({ items: result.data, type: types.LOAD_DELIVERIES_COMPLETED });
}

function* loadPaySystemsSaga() {
    const result = yield axios({
        url: '/api/operator/paySystem/list'
    });

    yield put({ items: result.data, type: types.LOAD_PAY_SYSTEMS_COMPLETED });
}

function* loadMetroStations({ cityId }) {
    let url = '/api/operator/metro/list';
    if (cityId) {
        url += `?cityId=${cityId}`;
    }

    const result = yield axios({ url });

    yield put({ items: result.data, type: types.LOAD_METRO_STATIONS_COMPLETED });
}

function* loadCountries() {
    const result = yield axios({
        url: '/api/operator/location/countries'
    });

    yield put({ items: result.data, type: types.LOAD_COUNTRIES_COMPLETED });
}

function* loadCities() {
    const result = yield axios({
        url: '/api/operator/location/cities'
    });

    yield put({ items: result.data, type: types.LOAD_CITIES_COMPLETED });
}

function* loadUser({ id }) {
    let user;
    if (id) {
        let result = yield axios({
            url: `/api/marketer/user/one?id=${id}`
        });
        user = result.data;
    } else {
        user = {};
    }

    yield put({ user, type: types.LOAD_USER_COMPLETED });
}

function* prepareOrderPage({ id, typeId }) {
    yield loadBasketStatusesSaga({ typeId });

    yield loadOneOrderSaga({ id, typeId });

    const state = yield select();
    const order = getOrderDraftOrder(state);

    yield all([
        loadOrderStatusesSaga(),
        loadDeliveriesSaga({ typeId: order.TYPE_ID }),
        loadPaySystemsSaga(),
        loadMetroStations({ cityId: order.CITY_ID }),
        loadCountries(),
        loadCities(),
        loadUser({ id: order.USER_ID })
    ]);

    yield put({ type: types.LOAD_ONE_ORDER_COMPLETED_FULLY });
}


function* loadOrdersSaga() {
    yield call(delay, 500);
    const state = yield select();
    const page = getOrdersPage(state);
    const pageSize = getOrdersPageSize(state);
    const search = getOrdersSearch(state);

    const getValues = (getById, getSelected) => R.map(id => getById(id, state).name, getSelected(state));

    const result = yield axios({
        url: '/api/operator/order/list',
        params: R.pickBy((val, key) => !!val, {
            page,
            page_size: pageSize,
            sourceStock: getOrderSourceStockId(state),
            searchValues: search ? [search] : undefined,
            article: getOrdersSearchArticle(state),
            size: getOrdersSearchSize(state),
            deliveryDateFrom: getOrdersDateDeliveryFrom(state),
            deliveryDateTo: getOrdersDateDeliveryTo(state),
            insertFromDate: getOrdersDateCreateFrom(state),
            insertToDate: getOrdersDateCreateTo(state),
            tagsData: {
                sourceName: {
                    values: getValues(getOrderSourceById, getOrderSourcesSelected)
                },
                aggregatedName: {
                    values: getValues(getOrderAggregatedByValue, getOrderAggregatedSelected)
                },
                typeName: {
                    values: getValues(getOrderTypeById, getOrderTypesSelected)
                },
                statusName: {
                    values: getValues(getOrderStatusById, getOrderStatusesSelected)
                }
            }
        }),
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        },
        withCredentials: true
    });

    yield put({ ...result.data, type: types.LOAD_ORDERS_COMPLETED });
    yield emptyExportLinkSaga();
}

function* createOrderByIdSaga({ history, id, typeId }) {
    const params = new URLSearchParams();
    params.append('id', id);
    params.append('typeId', typeId);

    const result = yield axios({
        method: 'post',
        url: '/api/operator/order/createById?json',
        data: params,
        withCredentials: true
    });

    history.push(`/operator/orders/${result.data.ID}`);
}

function* createOrderByCartSaga({ cartId }) {

    const result = yield axios({
        method: 'post',
        url: '/api/operator/order/createBySessionId',
        data: qs.stringify({ sessionId: cartId }),
        withCredentials: true
    });

    const order = { USER_DATA: {}, ...result.data };

    yield put({
        order,
        type: types.LOAD_ONE_ORDER_COMPLETED
    });
}


function* addToDraftBasketSaga({ id, size }) {
    const state = yield select();

    const order = getOrderDraftOrder(state);

    const params = qs.stringify({
        id: order.ID,
        productId: id,
        sizes: [size],
        qty: 1
    });

    const result = yield axios({
        method: 'post',
        url: '/api/operator/order/createBasket?json',
        data: params,
        withCredentials: true
    });

    yield put({
        type: types.ADD_NEW_BASKETS_TO_DRAFT,
        baskets: result.data
    });
}

function* updateDeliveryPrice({ field, value }) {
    if (field[0] !== 'DELIVERY_ID') {
        return
    }

    const state = yield select();

    const delivery = getDeliveries(state).filter(one => one.id === value)[0];

    yield put(setDraftOrderField(['PRICE_DELIVERY'], delivery.price));
}

function* searchProductsSaga() {
    const state = yield select();

    const search = getOrderDraftProductsSearch(state);

    const result = yield axios({
        method: 'get',
        url: '/api/products',
        params: {
            tags: [search],
            filters: {
                PROSPECTIVE: ['N'],
            }
        }
    });

    yield put({
        type: types.SEARCH_PRODUCTS_COMPLETED,
        products: result.data.products
    });
}

export default function* () {
    yield all([
        takeLatest(types.LOAD_ORDERS, loadOrdersSaga),
        takeEvery(types.CREATE_ORDER_BY_ID, createOrderByIdSaga),
        takeEvery(types.LOAD_ONE_ORDER, prepareOrderPage),
        takeEvery(types.SEND_CONFIRMATION_EMAIL, sendConfirmationEmailSaga),
        takeEvery(types.SEND_CONFIRMATION_SMS, sendConfirmationSmsSaga),
        takeEvery(types.SEND_ECARD_SMS, sendEcardSmsSaga),
        takeEvery(types.SEND_DELIV_SMS, sendDelivSmsSaga),
        takeEvery(types.SEND_PAYMENT_URL, sendPaymentUrlSaga),
        takeEvery(types.VIEW_PAYMENT_URL, viewPaymentUrlSaga),
        takeEvery(types.SEND_PAYMENT_SMS, sendPaymentSmsSaga),
        takeLatest(types.CALC_PRICES, calcPricesSaga),
        takeLatest(types.SAVE_ORDER, saveOrderSaga),
        takeLatest(types.SEARCH_PRODUCTS, searchProductsSaga),
        takeLatest(types.ADD_TO_DRAFT_BASKET, addToDraftBasketSaga),
        takeLatest(types.LOAD_LAST_ORDER_ID, loadLastOrderIdSaga),
        takeLatest(types.LOAD_ORDER_BY_CART, createOrderByCartSaga),
        takeEvery(types.CHECK_NEW_ORDER, checkNewOrderSaga),
        takeEvery(types.SET_DRAFT_ORDER_FIELD, updateDeliveryPrice),
        takeEvery(types.ORDERS_EXPORT, ordersExportSaga),
        takeEvery(types.ORDERS_EXPORT_UNSET_LINK, emptyExportLinkSaga)
    ]);
}