import { createSelector } from 'reselect';
import R from 'ramda';

const defaultArray = [];
const defaultObject = {};

const createListSelector = (listPath, hashPath) => createSelector(
    state => R.path(listPath, state) || defaultArray,
    state => R.path(hashPath, state) || defaultObject,
    (ids, byIds) => R.map(id => byIds[id], ids)
);

const createListSelectorDefault = path => createListSelector(R.append('ids', path), R.append('byIds', path));

const uncurry = f => R.uncurryN(f.length + 1, f);


export const getOrdersPage = state => parseInt(R.path(['page'])(state), 10);
export const getOrdersTotal = state => parseInt(R.path(['total'])(state), 10);
export const getOrdersPageSize = state => parseInt(R.path(['pageSize'])(state), 10);

export const getOrdersLoading = R.path(['loading']);
export const getOrdersLoaded = R.path(['loaded']);

export const getLastOrderId = R.path(['lastId']);

export const getOrdersList = createListSelectorDefault([]);
export const getOrderById = uncurry(id => R.path(['byIds', id]));

export const getBasketStatuses = R.path(['basketStatuses']);
export const getDeliveries = R.path(['deliveries']);
export const getPaySystems = R.path(['paySystems']);
export const getMetroStations = R.path(['metroStations']);
export const getCities = R.path(['cities']);
export const getCountries = R.path(['countries']);

export const getOrderSourceStocks = createListSelectorDefault(['sourceStocks']);
export const getOrderSources = createListSelectorDefault(['sources']);
export const getOrderTypes = createListSelectorDefault(['types']);
export const getOrderAggregated = createListSelectorDefault(['aggregated']);
export const getOrderStatuses = createListSelectorDefault(['statuses']);

export const getOrderSourceStockById = uncurry(id => R.path(['byIds', id]));
export const getOrderSourceById = uncurry(id => R.path(['sources', 'byIds', id]));
export const getOrderTypeById = uncurry(id => R.path(['types', 'byIds', id]));
export const getOrderAggregatedByValue = uncurry(value => R.path(['aggregated', 'byIds', value]));
export const getOrderStatusById = uncurry(id => R.path(['statuses', 'byIds', id]));

export const getOrderSourceStockId = R.path(['inputFilters', 'sourceStockId']);
export const getOrderSourcesSelected = R.path(['inputFilters', 'sources']);
export const getOrderTypesSelected = R.path(['inputFilters', 'types']);
export const getOrderAggregatedSelected = R.path(['inputFilters', 'aggregated']);
export const getOrderStatusesSelected = R.path(['inputFilters', 'statuses']);

export const getOrdersSearch = R.path(['inputFilters', 'search']);
export const getOrdersSearchArticle = R.path(['inputFilters', 'article']);
export const getOrdersSearchSize = R.path(['inputFilters', 'size']);

export const getOrdersDateDeliveryFrom = R.path(['inputFilters', 'deliveryFrom']);
export const getOrdersDateDeliveryTo = R.path(['inputFilters', 'deliveryTo']);
export const getOrdersDateCreateFrom = R.path(['inputFilters', 'createFrom']);
export const getOrdersDateCreateTo = R.path(['inputFilters', 'createTo']);

export const getOrderDraftLoading = R.path(['draft', 'loading']);
export const getOrderDraftError = R.path(['draft', 'error']);
export const getOrderDraftOrder = R.path(['draft', 'order']);
export const getOrderDraftUser = R.path(['draft', 'user']);
export const getOrderDraftConfirmationEmail = R.path(['draft', 'confirmationEmail']);
export const getOrderDraftConfirmationSms = R.path(['draft', 'confirmationSms']);
export const getOrderDraftecardSms = R.path(['draft', 'ecardSms']);
export const getOrderDraftdelivSms = R.path(['draft', 'delivSms']);
export const getOrderDraftPricesProcessing = R.path(['draft', 'pricesProcessing']);
export const getOrderDraftPaymentUrl = R.path(['draft', 'paymentUrl']);
export const getOrderDraftPaymentSms = R.path(['draft', 'paymentSms']);
export const getOrderDraftProductsSearch = R.path(['draft', 'productsSearch']);
export const getOrderDraftProducts = R.path(['draft', 'products']);
//export const getOrderDraftOrdersExport = R.path(['draft', 'ordersExport']);
export const getOrdersExport = R.path(['draft', 'ordersExport']);