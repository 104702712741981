import React from 'react';
import {connect} from 'react-redux';
import Filter from '../../../../components/Filter';
import {
  loadBannersData,
  toggleActivityType,
  toggleType,
  loadBannerTypes
} from '../../../../modules/marketer/banners/index';
const {
  getBannersActivityTypesSelected,
  getBannersTypesSelected,
  getBannerTypes
} = require('../../../../selectors').default;

const bannerActivityFilterOptions = [
  {id: '0', name: 'Нет'},
  {id: '1', name: 'Да'}
];

class BannersListSidebar extends React.PureComponent {
  componentDidMount() {
    this.props.loadBannerTypes();
  }
  render() {
    return (
      <div>
        {this.renderActivityFilter()}
        {this.renderBannerTypesFilter()}
      </div>
    );
  }
  renderActivityFilter() {
    return <Filter
      label="Активность"
      items={bannerActivityFilterOptions}
      selected={this.props.activityTypesSelected}
      onToggle={this.props.toggleActivityType}
    />;
  }
  renderBannerTypesFilter() {
    return <Filter
      label="Тип"
      items={this.props.types}
      selected={this.props.typesSelected}
      onToggle={this.props.toggleType}
    />;
  }
}

export default connect(
  state => ({
    activityTypesSelected: getBannersActivityTypesSelected(state),
    typesSelected: getBannersTypesSelected(state),
    types: getBannerTypes(state)
  }),
  dispatch => ({
    loadBannerTypes() {
      dispatch(loadBannerTypes());
    },
    toggleActivityType(id) {
      dispatch(toggleActivityType(id));
      dispatch(loadBannersData());
    },
    toggleType(id) {
      dispatch(toggleType(id));
      dispatch(loadBannersData());
    }
  })
)(BannersListSidebar);
