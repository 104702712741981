import React from 'react';
import Table, {TableHead, TableBody, TableRow} from 'material-ui/Table';
import {default as TableCell} from './OrdersTableCell';
import BasketsListRow from '../containers/BasketsListRow';


const shopsColumnStyle = {
  width: 80
};

const tableStyle = {
  overflow: 'visible'
};

class BasketsList extends React.Component {
  render() {
    const baskets = (this.props.baskets || []).filter(one => !one.removed);
    if (!baskets.length) {
      return <div>Товары отсутствуют</div>;
    }
    return (
      <Table style={tableStyle}>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Фото</TableCell>
            <TableCell>
              Артикул<br/>
              Бренд<br/>
              Сезон<br/>
              Размер
            </TableCell>
            <TableCell>
              Кол-во
			</TableCell>
		  	<TableCell>
              На складе
            </TableCell>
            <TableCell>
              Цена<br/>
              Скидка<br/>
              Цена со скидкой
            </TableCell>
			<TableCell>
              Продан
            </TableCell>
			<TableCell>
              Промокод
            </TableCell>
            <TableCell>
              Статус<br/>
              Комментарий<br/>
              Комментарий для товароведа
            </TableCell>
            <TableCell style={shopsColumnStyle}>Магазины</TableCell>
						<TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {baskets.map((basket, index) =>
            <BasketsListRow key={index} index={index} basket={basket}/>
          )}
        </TableBody>
      </Table>
    );
  }
}

export default BasketsList;
