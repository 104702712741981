import React from 'react';
import { CircularProgress } from 'material-ui/Progress';


class Loader extends React.PureComponent {

  style = {
    marginTop: '10%',
    width: '100%',
    textAlign: 'center'
  };

  progressStyle = {
    width: 100
  }

  render() {
    return (
      <div style={this.style}>
        <CircularProgress
          style={this.progressStyle}
        />
      </div>
    );
  }
}

export default Loader;
