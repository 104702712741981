import React from 'react';
import moment from 'moment';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';

class OrderDateFilter extends React.PureComponent {

  mode = 'input';

  containerStyle = {
    flex: 'none',
    display: 'flex'
  };

  datePickerStyle = {
    flex: 'none'
  };

  textFieldStyle = {
    width: 110,
    fontSize: 11
  };

  onChange = e => {
    if (e.target.value) {
      this.props.onChange(e.target.value);
      return;
    }

    if (this.mode === 'clean') {
      this.props.onChange(null);
    }
  };

  onClick = e => {
    this.mode = 'clean';
    setTimeout(() => {
      this.mode = 'input';
    }, 100);
  }

  onClear = e => {
    this.props.onChange(null);
  };

  render() {
    return (
      <div style={this.containerStyle}>
        <TextField
          label={this.props.label}
          value={this.props.value ? moment(this.props.value).format('YYYY-MM-DD') : ' '}
          onChange={this.onChange}
          onClick={this.onClick}
          style={this.textFieldStyle}
          type="date"
        />
        <IconButton onClick={this.onClear} style={{ height: 24, width: 24, marginTop: 14, }}>
          <Icon className="material-icons" style={{ fontSize: 18, }}>clear</Icon>
        </IconButton>
      </div>
    );
  }
}

export default OrderDateFilter;
