import React from 'react';
import {connect} from 'react-redux';
import Input from 'material-ui/Input/Input';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import {setDraftProductsSearch, searchProducts} from '../../../modules/orders';

const {getOrderDraftProductsSearch} = require('../../../selectors').default;


const searchStyle = {
  height: 45
};

const searchIconButtonStyle = {
  width: 32,
  height: 32,
  verticalAlign: 'top'
};

const searchInputStyle = {
  width: 500,
  marginRight: 10
};

class ProductsSearch extends React.Component {
  onChange = e => {
    this.props.handleSearchChange(e.target.value);
  };

  onKeyUp = e => {
    if (e.keyCode === 13) {
      this.props.handleSearch();
    }
  };

  render() {
    return (
      <div style={searchStyle}>
        <Input
          placeholder="Поиск"
          value={this.props.search}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          style={searchInputStyle}
        />
        <IconButton
          color="accent"
          onClick={this.props.handleSearch}
          style={searchIconButtonStyle}
        >
          <Icon className="material-icons">search</Icon>
        </IconButton>
      </div>
    );
  }
}


export default connect(
  state => ({
    search: getOrderDraftProductsSearch(state)
  }),
  dispatch => ({
    handleSearchChange(value) {
      dispatch(setDraftProductsSearch(value));
    },
    handleSearch() {
      dispatch(searchProducts());
    }
  })
)(ProductsSearch);
