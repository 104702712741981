import Icon from "material-ui/Icon";
import IconButton from "material-ui/IconButton";
import List, { ListItem, ListItemText } from "material-ui/List";
import TextField from "material-ui/TextField";
import R from "ramda";
import React from "react";
import onClickOutside from "react-onclickoutside";

const style = {
  textField: {
    cursor: "pointer",
    fontSize: 13,
    width: "calc(100% - 25px)",
  },
  list: {
    position: "absolute",
    background: "white",
    zIndex: 1000,
    maxHeight: 130,
    border: "1px solid lightgrey",
    borderTop: "none",
    overflowY: "auto",
  },
  container: {
    position: "relative",
  },
};

class CustomSelect extends React.PureComponent {
  handleClickOutside = (evt) => {
    this.onRequestClose();
  };

  componentDidMount() {
    this.setState({
      text: this.getText(this.props.value),
    });
  }

  state = {
    open: false,
    text: "",
  };

  onRequestClose = () => {
    this.setState({
      open: false,
      text: this.state.text || this.getText(this.props.value),
    });
  };

  memoizedOnSelect = R.memoize((id) => (e) => {
    e.preventDefault();
    this.onRequestClose();

    if (this.props.onChange) {
      this.props.onChange(id);
    }
  });

  onOpen = (e) => {
    this.setState({
      open: true,
      text: "",
    });
  };

  componentWillReceiveProps(props) {
    this.setState({
      text: this.getText(props.value),
    });
  }

  onTextChange = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      if (this.props.onChange) {
        const filtered = this.filteredOptions();
        const id = filtered.length > 0 ? filtered[0].id : null;
        if (id) {
          this.onRequestClose();
          this.props.onChange(id);
        }
      }
    }
  };

  getText(id) {
    if (!this.props.options) {
      return "";
    }
    const option = R.find(R.propEq("id", id), this.props.options);
    return option && option.text !== undefined ? option.text : "";
  }

  filteredOptions() {
    let current;
    if (typeof this.state.text === "string") {
      current = this.state.text.toLowerCase();
    } else {
      current = this.state.text.toString();
    }

    return this.props.options
      ? this.props.options.filter(({ text }) => {
          if (typeof text !== "string") {
            text = text.toString();
          }
          return text.toLowerCase().indexOf(current) === 0;
        })
      : [];
  }

  onClear = () => {
    this.setState({
      text: "",
    });
    this.props.onChange(0);
  };

  render() {
    const options = this.filteredOptions();
    const listStyle = {
      ...style.list,
      display: this.state.open ? "block" : "none",
    };

    return (
      <div style={style.container}>
        <TextField
          style={style.textField}
          label={this.props.label}
          onChange={this.onTextChange}
          onMouseDown={this.onOpen}
          onKeyUp={this.onKeyUp}
          value={this.state.text}
        />
        <List style={listStyle} disablePadding dense>
          {options.map((one, index) => (
            <ListItem key={one.id} button>
              <ListItemText
                primary={one.text}
                onClick={this.memoizedOnSelect(one.id)}
              />
            </ListItem>
          ))}
        </List>
        <IconButton
          onClick={this.onClear}
          style={{ height: 24, width: 24, marginTop: 14 }}
        >
          <Icon className="material-icons" style={{ fontSize: 18 }}>
            clear
          </Icon>
        </IconButton>
      </div>
    );
  }
}

export default onClickOutside(CustomSelect);
