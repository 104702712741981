import React from 'react';
import {TableRow, TableCell} from 'material-ui/Table';


class ReviewsHeaderRow extends React.PureComponent {
  render() {
    return (
      <TableRow>
        <TableCell>
          ИД
        </TableCell>
        <TableCell>
          Дата
        </TableCell>
        <TableCell>
          Имя клиента
        </TableCell>
        <TableCell>
          Email клиента
        </TableCell>
        <TableCell>
          Сообщение
        </TableCell>
        <TableCell />
      </TableRow>
    )
  }
}

export default ReviewsHeaderRow;
